/* eslint-disable max-lines */
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Media, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import UserService from '../../services/User';

const MobileNotificationsNavbar = () => {
  const { userNotifications, token, updateUserNotifications }: any = useContext(AuthContext);
  const [modalConfigNotify, setModalConfigNotify] = useState(false);
  const toggleModalNotify = () => setModalConfigNotify(!modalConfigNotify);
  const [notificationList, setNotificationList] = useState<any>([]);

  const readNotification = async (id: string) => {
    await UserService(token).readNotification({ id: [id] });
    updateUserNotifications();
  };

  const clearAllNotifications = async () => {
    const notificationIds = notificationList.map((item: any) => {
      return item.id;
    });

    await UserService(token).readNotification({ id: notificationIds });
    updateUserNotifications();
  };

  const notificationListItems = () => {
    return Object.keys(notificationList).map((i) => {
      return (
        <>
          <DropdownItem
            to="#"
            tag={Link}
            onClick={() => {
              readNotification((notificationList as any)[i].id);
            }}
            style={{ width: '400px' }}
            key={`baseItem-${i}`}
          >
            <div className="clearfix">
              <div className="float-left" style={{ paddingRight: '10px' }}>
                <img
                  className="rounded-circle"
                  style={{ width: 40, height: 40 }}
                  src={(notificationList as any)[i].picture}
                  alt={``}
                />
                <p className="text-center small">
                  <b>{(notificationList as any)[i].data.name}</b>
                </p>
              </div>
              <div className="float-left" style={{ width: '85%' }}>
                <div>
                  <b>{(notificationList as any)[i].data.title}</b>
                </div>
                <div>{(notificationList as any)[i].data.description}</div>
                <div className="text-right">
                  {(notificationList as any)[i].type === 'employeeBirthdays'
                    ? 'Today'
                    : moment((notificationList as any)[i].data.occurAt)
                        .utc()
                        .fromNow()}
                </div>
              </div>
            </div>
          </DropdownItem>
          <DropdownItem divider={true}></DropdownItem>
        </>
      );
    });
  };

  useEffect(() => {
    setNotificationList(userNotifications);
  }, [userNotifications]);

  return (
    <UncontrolledDropdown nav style={{ marginLeft: 15 }}>
      <DropdownToggle className="pr-0" nav style={{ paddingLeft: '0' }}>
        <Media className="align-items-center">
          <i className="fas fa-bell" style={{ fontSize: 'x-large', paddingTop: '7px' }}></i>
          <Badge
            color="primary"
            className="badge-md badge-circle badge-floating border-white"
            style={{
              width: '1.5rem',
              height: '1.5rem',
              position: 'absolute',
              // top: '25%',
              transform: 'translate(-50%, -50%)',
              margin: '6px 22px',
              padding: '0',
            }}
          >
            {notificationList.length}
          </Badge>
        </Media>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right style={{ zIndex: '99999999', minWidth: '400px' }}>
        <DropdownItem className="noti-title" header tag="div">
          <h6 className="text-overflow m-0">
            Notifications
            <div style={{ float: 'right' }}>
              <a onClick={toggleModalNotify} style={{ cursor: 'pointer' }}>
                <i className="fas fa-2x fa-cog"></i>
              </a>
            </div>
          </h6>
        </DropdownItem>
        {notificationListItems()}

        <DropdownItem
          className="text-center"
          onClick={() => {
            clearAllNotifications();
          }}
        >
          <span>Clear All</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default MobileNotificationsNavbar;

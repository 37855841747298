/* eslint-disable max-lines */
import { useContext, useState, forwardRef, Ref, useEffect } from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Context as AuthContext } from '../../contexts/AuthContext';
import {
  Collapse,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import BaseHome from '../../views/pages/BaseHome';
import isAuthorized from '../../helpers/isAuthorized';
import QrCode from '../../components/Core/QrCode';

import { motion, useAnimation } from 'framer-motion';
import isAdmin from '../../helpers/isAdmin';
import hasKids from '../../helpers/hasKids';
import isParentAndPaying from '../../helpers/isParentAndPaying';
import SearchNavbar from '../Navbars/SearchNavbar';
import MobileNotificationsNavbar from '../Navbars/MobileNotificationsNavbar';
import confirmAlert from '../../helpers/confirmAlert';
import isParent from '../../helpers/isParent';
import isStaff from '../../helpers/isStaff';
import isTeacher from '../../helpers/isTeacher';

const Sidebar = forwardRef((props: any, ref: Ref<HTMLDivElement>) => {
  const isTablet = window.innerWidth <= 1112 && window.innerWidth > 768 && isMobile;
  const { handleLogout, handleCancelAccount, user, userTotalUnreadMessages }: any = useContext(AuthContext);
  const showNavBarSearch = !isParent(user) && !isStaff(user) && !isTeacher(user);

  const logout = async (e: any) => {
    e.preventDefault();
    handleLogout();
  };

  const confirmCancelAccount = (e: any) => {
    e.preventDefault();

    return confirmAlert({
      title: 'Confirm',
      messageType: 'DELETE',
      message: 'Are you sure you want to delete your account?',
      onClickYes: () => {
        handleCancelAccount();
        logout(e);
        closeCollapse();
        setItemCollapseOpen('');
      },
    });
  };

  const [collapseOpen, setCollapseOpen] = useState(false);
  const [itemCollapseOpen, setItemCollapseOpen] = useState('');

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  useEffect(() => {
    if (props.sidebarMini) {
      setItemCollapseOpen('');
    }
  }, [props.sidebarMini]);

  const createLinks = (routes: any) => {
    const mini = props.sidebarMini;
    let menu: any[] = [];
    if (isMobile && user) {
      menu.push(
        <NavItem>
          <NavLink
            to={`/users/${user.id}/profile`}
            tag={NavLinkRRD}
            onClick={() => {
              closeCollapse();
              setItemCollapseOpen('');
            }}
            activeClassName="active"
            className={`${mini ? 'd-flex justify-content-center align-items-center m-0' : ''}`}
          >
            {mini && <i style={{ minWidth: 0, fontSize: '1rem' }} className="fas fa-user text-primary" />}
            {!mini && (
              <>
                <i className="fas fa-user text-primary" />
                <span>My Profile</span>
              </>
            )}
          </NavLink>
          <hr className="my-1 bg-transparent border-top-0 divider" />
        </NavItem>,
      );
    }
    const m = routes
      .filter((r: any) => {
        if (!r.isItemMenu) {
          return false;
        }
        const { user }: any = useContext(AuthContext);
        if (r.onlyAdmin) {
          if (!r.onlyParent || !r.onlyParentPaying) {
            if (!r.onlyForGroups?.length || (r.onlyForGroups?.length && !r.onlyForGroups.includes('Manager'))) {
              return false;
            }
          }
          return isAdmin(user);
        }
        if (r.onlyParent) {
          return hasKids(user);
        }
        if (r.onlyParentPaying) {
          return isParentAndPaying(user) && !isAdmin(user);
        }
        if (r.onlyForGroups?.length) {
          return r.onlyForGroups?.indexOf(user.groupPermission.name) !== -1;
        }

        return isAuthorized(r, user);
      })
      .map((prop: any, index: any) => {
        const pathTo = prop.path.replace(':userId', user.id);
        if (!prop.isCollapsed) {
          return (
            <NavItem key={`nav-item-${index}`}>
              <NavLink
                to={pathTo}
                tag={NavLinkRRD}
                onClick={() => {
                  closeCollapse();
                  setItemCollapseOpen('');
                }}
                activeClassName="active"
                className={`${mini ? 'd-flex justify-content-center align-items-center m-0' : ''}`}
              >
                {mini && <i style={{ minWidth: 0, fontSize: '1rem' }} className={prop.icon} />}
                {!mini && (
                  <>
                    <i className={prop.icon} />
                    <span>{prop.name}</span>
                  </>
                )}
              </NavLink>
            </NavItem>
          );
        } else {
          return (
            <NavItem key={index}>
              <Button
                onClick={() => {
                  mini && props.toogleSideBarMini();
                  itemCollapseOpen !== prop.moduleName ? setItemCollapseOpen(prop.moduleName) : setItemCollapseOpen('');
                }}
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  background: '#FFFFFF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: isMobile ? '-3px' : '3px',
                  gap: '16px',
                  color: 'rgba(0, 0, 0, 0.5)',
                  fontWeight: '400',
                }}
              >
                {mini && <i style={{ minWidth: 0, fontSize: '1rem' }} className={prop.icon} />}
                {!mini && (
                  <>
                    <i className={prop.icon} />
                    <span>{prop.name}</span>
                    <i className={`fas fa-chevron-${itemCollapseOpen === prop.moduleName ? 'down' : 'right'}`}></i>
                  </>
                )}
              </Button>
              <Collapse isOpen={itemCollapseOpen === prop.moduleName}>
                <ul style={{ listStyle: 'none' }}>
                  <NavItem key={index}>
                    {prop.childs
                      .filter((r: any) => {
                        if (!r.isItemMenu) {
                          return false;
                        }
                        if (r.onlyForGroups?.length) {
                          return r.onlyForGroups?.indexOf(user.groupPermission.name) !== -1;
                        }
                        if (isMobile && ['/imports'].includes(r.path)) {
                          return false;
                        }
                        return isAuthorized(r, user);
                      })
                      .map((child: any, index: any) => (
                        <NavLink
                          key={`nav-link-child-${index}`}
                          to={child.path}
                          tag={NavLinkRRD}
                          onClick={() => {
                            closeCollapse();
                            itemCollapseOpen !== prop.moduleName
                              ? setItemCollapseOpen(prop.moduleName)
                              : setItemCollapseOpen('');
                          }}
                          activeClassName="active"
                          className={`${mini ? 'd-flex justify-content-center align-items-center m-0' : ''}`}
                        >
                          {child.name}
                        </NavLink>
                      ))}
                  </NavItem>
                </ul>
              </Collapse>
            </NavItem>
          );
        }
      });
    menu = [...menu, ...m];
    if (isMobile) {
      menu.push(
        <NavItem>
          <hr className="my-1 bg-transparent border-top-0 divider" />
          <NavLink
            to={'/logout'}
            tag={NavLinkRRD}
            onClick={(e) => {
              logout(e);
              closeCollapse();
              setItemCollapseOpen('');
            }}
            activeClassName="active"
            className={`${mini ? 'd-flex justify-content-center align-items-center m-0' : ''}`}
          >
            {mini && <i style={{ minWidth: 0, fontSize: '1rem' }} className="fas fa-sign-out-alt text-muted" />}
            {!mini && (
              <>
                <i className="fas fa-sign-out-alt text-red" />
                <span>Logout</span>
              </>
            )}
          </NavLink>
          <NavLink
            to={'/logout'}
            tag={NavLinkRRD}
            onClick={(e) => {
              confirmCancelAccount(e);
            }}
            activeClassName="active"
            className={`${mini ? 'd-flex justify-content-center align-items-center m-0' : ''}`}
          >
            {mini && <i style={{ minWidth: 0, fontSize: '1rem' }} className="fas fa-user-times text-red" />}
            {!mini && (
              <>
                <i className="fas fa-user-times text-muted" />
                <span>Cancel Account</span>
              </>
            )}
          </NavLink>
        </NavItem>,
      );
    }

    return menu;
  };

  const { routes } = props;
  const controls = useAnimation();
  const variants = {
    mini: { width: !isMobile || isTablet ? 70 : '100%' },
    normal: { width: !isMobile ? 250 : '100%' },
  };

  useEffect(() => {
    controls.start(props.sidebarMini ? 'mini' : 'normal');
  }, [props.sidebarMini, controls]);

  return (
    <>
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal} className="pb-0">
          My Code
        </ModalHeader>
        <ModalBody className="pt-0">
          <QrCode />
        </ModalBody>
        <ModalFooter style={{ textAlign: 'center' }}>
          <span className="h2 font-weight-bold" style={{ margin: '0 auto' }}>{`${user?.pin || '-'}`}</span>
        </ModalFooter>
      </Modal>
      <motion.nav
        ref={ref}
        animate={controls}
        variants={variants}
        className={`navbar-vertical fixed-left navbar-light bg-white ${
          !isMobile || isTablet ? 'pt-3 navbar-expand-md fixed-top' : 'mobile-top pt-1 pb-3 navbar-expand-xl'
        } ${props.sidebarMini ? 'navbar-small d-flex flex-column align-items-center' : ''}`}
        id="sidenav-main"
      >
        {props.children}
        <Container fluid>
          {isMobile && !isTablet && (
            <>
              <div>
                <div className="float-left">
                  <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                    <span className="navbar-toggler-icon" />
                  </button>
                </div>
                <div className="float-left" style={{ maxWidth: window.innerWidth - 56 - 97 - 20 + 'px' }}>
                  {showNavBarSearch ? <SearchNavbar /> : <></>}
                </div>
                <div className="float-right" style={{ paddingRight: '12px' }}>
                  <a
                    aria-haspopup="true"
                    className="nav-link"
                    aria-expanded="true"
                    href="/messaging"
                    style={{ display: 'inline-block', padding: 0 }}
                  >
                    <i className="fas fa-comment" style={{ fontSize: 'x-large', paddingTop: '7px' }}></i>
                    <Badge
                      color="primary"
                      className="badge-md badge-circle badge-floating border-white"
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        position: 'absolute',
                        // top: '25%',
                        transform: 'translate(-50%, -50%)',
                        margin: '4px 2px 0px',
                        padding: '0',
                      }}
                    >
                      {userTotalUnreadMessages}
                    </Badge>
                  </a>
                  <MobileNotificationsNavbar />
                  <a
                    aria-haspopup="true"
                    className="nav-link"
                    aria-expanded="true"
                    href="/home"
                    style={{ display: 'inline-block', padding: 0, marginLeft: 10 }}
                  >
                    <i className="fa fa-home" style={{ fontSize: 'x-large', paddingTop: '7px' }}></i>
                  </a>
                </div>
              </div>
            </>
          )}

          {!isMobile ||
            (isTablet && (
              <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                <span className="navbar-toggler-icon" />
              </button>
            ))}

          <Collapse navbar isOpen={collapseOpen} className={isMobile && !isTablet ? 'show-navbar' : ''}>
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-close" xs="6"></Col>
                <Col className="collapse-close text-right" xs="6">
                  <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                    <span />
                    <span />
                    {/* <i className="fas fa-times" /> */}
                  </button>
                </Col>
              </Row>
            </div>
            {/* TODO: Mocked Data */}
            {(!isMobile || isTablet) && (
              <>
                <Row style={{ textAlign: 'center' }} className="mt-4 mb-3 d-md-none">
                  <Col>
                    <a aria-haspopup="true" className="nav-link" aria-expanded="true" href="messaging">
                      <i className="fas fa-comment" style={{ fontSize: 'xx-large', paddingTop: '7px' }}></i>
                      <Badge
                        color="primary"
                        className="badge-md badge-circle badge-floating border-white"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          margin: '-15px 10px 0px',
                          padding: '0',
                        }}
                      >
                        4
                      </Badge>
                    </a>
                  </Col>
                  <Col>
                    <UncontrolledDropdown nav>
                      <DropdownToggle className="pr-0" nav style={{ paddingLeft: '0' }}>
                        <Media className="align-items-center">
                          <i className="fas fa-bell" style={{ fontSize: 'xx-large', paddingTop: '7px' }}></i>
                          <Badge
                            color="primary"
                            className="badge-md badge-circle badge-floating border-white"
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              margin: '-15px 10px 0px',
                              padding: '0',
                            }}
                          >
                            4
                          </Badge>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem className="noti-title" header tag="div">
                          <h6 className="text-overflow m-0">Notifications</h6>
                        </DropdownItem>
                        <DropdownItem to="#">
                          <i className="ni ni-single-02" />
                          <span>Not 1</span>
                        </DropdownItem>
                        <DropdownItem to="#">
                          <i className="ni ni-single-02" />
                          <span>Not 2</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                  <Col>
                    <UncontrolledDropdown nav>
                      <DropdownToggle className="pr-0" nav style={{ paddingLeft: '0' }}>
                        <Media className="align-items-center">
                          <span className="avatar avatar-sm rounded-circle">
                            <img
                              alt={`${user.firstName}`}
                              src={
                                user?.picture ? `${user.picture}` : require('../../assets/img/user-default.png').default
                              }
                              style={{ height: '100%' }}
                            />
                          </span>
                          <Media className="ml-2 d-none d-lg-block">
                            <span className="mb-0 text-sm font-weight-bold">{`${user.firstName || '-'} ${
                              user.lastName || ''
                            }`}</span>
                          </Media>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem className="noti-title" header tag="div">
                          <h6 className="text-overflow m-0">Welcome!</h6>
                        </DropdownItem>
                        <DropdownItem to={`/users/${user.id}/profile`} tag={Link}>
                          <i className="ni ni-single-02" />
                          <span>My profile</span>
                        </DropdownItem>
                        <DropdownItem onClick={toggleModal} to="#" tag={Link}>
                          <i className="ni ni-badge" />
                          <span>My Code</span>
                        </DropdownItem>

                        <DropdownItem to="#" tag={Link}>
                          <i className="ni ni-support-16" />
                          <span>
                            <span className="font-weight-light">REF: </span> {`${user?.pin || '-'}`}
                          </span>
                        </DropdownItem>
                        <DropdownItem divider />

                        <DropdownItem href="/logout" onClick={logout}>
                          <i className="ni ni-user-run" />
                          <span>Logout</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                </Row>

                <Form className="mt-4 mb-3 d-md-none">
                  <InputGroup className="input-group-rounded input-group-merge">
                    <Input
                      aria-label="Search"
                      className="form-control-rounded form-control-prepended"
                      placeholder="Search"
                      type="search"
                    />
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <span className="fa fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Form>
                <BaseHome sidebarMini={props.sidebarMini} />
              </>
            )}
            <Nav navbar className="menu-route-principal">
              {createLinks(routes)}
            </Nav>
          </Collapse>
        </Container>
      </motion.nav>
    </>
  );
});

Sidebar.defaultProps = {
  routes: [{}],
  sidebarmin: false,
};

export default Sidebar;

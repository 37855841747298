import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import CommonHeader from '../../../components/Headers/CommonHeader';
import { useContext, useState, useEffect } from 'react';
import BillingInvoicesList from '../../../views/pages/Billing/BillingInvoicesList';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import UserService from '../../../services/User';
import FileTab from '../Kids/TabContent/FileTab';
import BillingFilesList from '../Billing/BillingFilesList';
import { isMobile } from 'react-device-detect';

const ParentsDocumentsList = () => {
  const imutableTabs = ['billing', 'tax-documents'];
  const { token, user }: any = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('billing');
  const [parentId, setParentId] = useState('');
  const [kidsId, setKidsId] = useState('');
  const [kidsList, setKidsList] = useState([] as any[]);
  const [tabs, setTabs] = useState(imutableTabs as any[]);
  const [loading, setLoading] = useState(false);
  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
    },
  };

  useEffect(() => {
    setParentId(user.id);
  }, [user]);

  useEffect(() => {
    listParentsKids();
  }, [parentId]);

  const listParentsKids = async () => {
    try {
      setLoading(true);

      const list: any[] = [];
      const result = await UserService(token).listKids(user.id);

      const userKids = result?.data || [];
      for (const uk of userKids) {
        const isExists = list.find((k) => String(k.id) === String(uk.kidsId));
        if (!isExists) {
          list.push(uk.kids);
        }
      }

      setTabs([
        ...list.map((item) => item.id + '||' + item.firstName.toLowerCase() + '-' + item.lastName.toLowerCase()),
        ...imutableTabs,
      ]);

      setKidsList(list);
      setKidsId(list[0].id);
      setLoading(false);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid user ID');
      setLoading(false);
    }
  };

  const getTabLabel = (index: string) => {
    const tabLabel: string[] = [];
    if (index.indexOf('||') !== -1) {
      index = index.split('||')[1];
    }
    index.split('-').forEach((item) => tabLabel.push(item.charAt(0).toUpperCase() + item.slice(1)));
    return tabLabel.join(' ');
  };

  const getTabContent = (index: string) => {
    if (index.indexOf('||') !== -1) {
      return <FileTab forceKidId={index.split('||')[0]} />;
    }

    if (index === 'billing') {
      return <BillingInvoicesList reload={loading} kidsList={kidsList} kidsId={kidsId} />;
    }

    if (index === 'tax-documents') {
      return <BillingFilesList />;
    }
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid style={isMobile ? { minHeight: 600 } : {}}>
        <Row className={`${!isMobile ? 'mt-5' : ''} home-waitingList`}>
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0 pb-0">
                <Row className="align-items-center">
                  <Col xs="9">
                    <h3 className="mb-0">Documents</h3>
                  </Col>
                  <Col xs="3" style={{ textAlign: 'right' }}></Col>
                </Row>
                <Nav className="border-0 mt-3" tabs>
                  {tabs.map((item) => {
                    return (
                      <NavItem className="cursor-pointer" key={`navItem-${item}`}>
                        <NavLink
                          className="border-0 cursor-pointer font-weight-bold"
                          style={customStyles.navLink}
                          active={activeTab === item}
                          onClick={() => setActiveTab(item)}
                        >
                          {getTabLabel(item)}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <TabContent activeTab={activeTab}>
                      {tabs.map((item) => {
                        return (
                          <TabPane tabId={item} key={`TabPane-${item}`}>
                            {getTabContent(item)}
                          </TabPane>
                        );
                      })}
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ParentsDocumentsList;

/*eslint-disable*/
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import ClassService from '../../../services/Class';
import history from '../../../history';
import SchoolService from '../../../services/School';
import ClassPriceSheetList from './ClassPriceSheetList';
import ClassIpadAccess from './ClassIpadAccess';
import CardHeaderTopFormButtons from '../../../components/Cards/CardHeaderTopFormButtons';
import { useHistory } from 'react-router-dom';

const ClassCreate = () => {
  const { id } = useParams<{ id: string }>();
  const pageTitle: string = id ? 'Update' : 'Create';
  const { token, currentSchool }: any = useContext(AuthContext);
  const history = useHistory();

  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [schoolId, setSchoolId] = useState('');
  const [capacity, setCapacity] = useState(0);
  const [roomNumber, setRoomNumber] = useState(0);
  const [enrollOnline, setEnrollOnline] = useState('NO');
  const [startAge, setStartAge] = useState(0);
  const [endAge, setEndAge] = useState(0);
  const [ageSteps, setAgeSteps] = useState('');
  const [legalRatioTeachers, setLegalRatioTeachers] = useState(0);
  const [legalRatioChildrens, setLegalRatioChildrens] = useState(0);
  const [desiredRatioTeachers, setDesiredRatioTeachers] = useState(0);
  const [desiredRatioChildrens, setDesiredRatioChildrens] = useState(0);
  const [personOnline, setPersonOnline] = useState('');
  const [cameras, setCameras] = useState([] as string[]);
  const [isInfantRoom, setIsInfantRoom] = useState(false);

  const [selectList, setSelectList] = useState([{}]);
  const [showAddCamera, setShowAddCamera] = useState(false);
  const [newCameraUrl, setNewCameraUrl] = useState('');

  const [classIpadAccessFormData, setClassIpadAccessFormData] = useState({ id: '', username: '', password: '' });

  const listSelectSchool = async () => {
    try {
      const result = await SchoolService(token).listSelect();
      setSelectList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Schools found');
    }
  };

  const getById = async (optionalId = null) => {
    try {
      const result = await ClassService(token).show(optionalId || id);
      if (result?.data) {
        setName(result?.data?.name || '');
        setSchoolId(result?.data?.schoolId || '');
        setCapacity(result?.data?.capacity || 0);
        setRoomNumber(result?.data?.roomNumber || 0);
        setEnrollOnline(result?.data?.enrollOnline || 'NO');
        setStartAge(result?.data?.startAge || 0);
        setEndAge(result?.data?.endAge || 0);
        setAgeSteps(result?.data?.ageSteps || '');
        setLegalRatioTeachers(result?.data?.legalRatioTeachers || 0);
        setLegalRatioChildrens(result?.data?.legalRatioChildrens || 0);
        setDesiredRatioTeachers(result?.data?.desiredRatioTeachers || 0);
        setDesiredRatioChildrens(result?.data?.desiredRatioChildrens || 0);
        setPersonOnline(result?.data?.personOnline || '');
        if (result?.data?.cameras?.length) {
          setCameras(result?.data?.cameras?.map((c: any) => `${c.url}`));
        }
        setClassIpadAccessFormData(
          result?.data?.classUsersRelations?.find((item: any) => item.isMain === true) || [].length
            ? result?.data.classUsersRelations.find((item: any) => item.isMain === true).user
            : { username: result.data.defaultUsername },
        );
        setIsInfantRoom(!!result?.data?.isInfantRoom);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid Class ID');
    }
  };

  useEffect(() => {
    if (id) {
      getById();
    }
    listSelectSchool();
  }, []);

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
  }, [currentSchool]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body = {
      ...{
        name,
        schoolId,
        capacity,
        roomNumber,
        enrollOnline,
        startAge,
        endAge,
        ageSteps,
        legalRatioTeachers,
        legalRatioChildrens,
        desiredRatioTeachers,
        desiredRatioChildrens,
        personOnline,
        cameras,
        isInfantRoom,
      },
      ...{
        user: {
          username: classIpadAccessFormData.username,
          password: classIpadAccessFormData.password || null,
          id: classIpadAccessFormData.id,
          isMain: true,
        },
      },
    };

    try {
      const result = id ? await ClassService(token).update(id, body) : await ClassService(token).create(body);
      toast.success(`${id ? 'Updated' : 'Created'} successfully!`);
      if (result?.data?.id) {
        history.push(`/class/${result?.data?.id}`);
        await getById(result?.data?.id);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
    setIsDisabled(false);
  };

  const deleteCamera = (i: number) => {
    const newCameras = JSON.parse(JSON.stringify(cameras));
    setCameras(newCameras.filter((_: any, index: number) => index !== i));
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <Form onSubmit={handleSubmit}>
                <CardHeaderTopFormButtons
                  pageTitle={pageTitle + ' Class'}
                  buttons={[
                    { label: 'Back', onClick: (e) => history.push('/class/list') },
                    { label: 'Save', color: 'success', onClick: (e) => handleSubmit(e) },
                  ]}
                />
                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-school-id">
                            School
                          </label>
                          <Input type="select" onChange={(e) => setSchoolId(e.target.value)} value={schoolId} disabled>
                            {selectList?.map((filter: any, index: number) => {
                              return (
                                <option value={filter.id} key={`filter-button-${index}`}>
                                  {filter.name}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="9">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-name">
                            Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-name"
                            placeholder="Class Name"
                            type="text"
                            disabled={isDisabled}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-capacity">
                            Capacity
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-capacity"
                            placeholder="Capacity"
                            type="number"
                            disabled={isDisabled}
                            value={capacity}
                            onChange={(e) => setCapacity(e.target.valueAsNumber)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-roomNumber">
                            Room Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-roomNumber"
                            placeholder="Room Number"
                            type="number"
                            disabled={isDisabled}
                            value={roomNumber}
                            onChange={(e) => setRoomNumber(e.target.valueAsNumber)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-enrollOnline">
                            Enroll Online
                          </label>
                          <Input type="select" onChange={(e) => setEnrollOnline(e.target.value)} value={enrollOnline}>
                            <option value="YES" key={`filter-button-yes`}>
                              Yes
                            </option>
                            <option value="NO" key={`filter-button-no`}>
                              No
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-startAge">
                            Start Age
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-startAge"
                            placeholder="Start Age"
                            type="number"
                            disabled={isDisabled}
                            value={startAge}
                            onChange={(e) => setStartAge(e.target.valueAsNumber)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-endAge">
                            End Age
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-endAge"
                            placeholder="End Age"
                            type="number"
                            disabled={isDisabled}
                            value={endAge}
                            onChange={(e) => setEndAge(e.target.valueAsNumber)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-ageSteps">
                            Age Steps
                          </label>
                          <Input type="select" onChange={(e) => setAgeSteps(e.target.value)} value={ageSteps}>
                            <option value="WEEKS" key={`filter-button-weeks`}>
                              Weeks
                            </option>
                            <option value="MONTHS" key={`filter-button-months`}>
                              Months
                            </option>
                            <option value="YEARS" key={`filter-button-years`}>
                              Years
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-legalRatioTeachers">
                            Legal Ratio Teachers
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-legalRatioTeachers"
                            placeholder="Legal Ratio Teachers"
                            type="number"
                            disabled={isDisabled}
                            value={legalRatioTeachers}
                            onChange={(e) => setLegalRatioTeachers(e.target.valueAsNumber)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-legalRatioChildrens">
                            Legal Ratio Childrens
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-legalRatioChildrens"
                            placeholder="Legal Ratio Childrens"
                            type="number"
                            disabled={isDisabled}
                            value={legalRatioChildrens}
                            onChange={(e) => setLegalRatioChildrens(e.target.valueAsNumber)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-desiredRatioTeachers">
                            Desired Ratio Teachers
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-desiredRatioTeachers"
                            placeholder="Desired Ratio Teachers"
                            type="number"
                            disabled={isDisabled}
                            value={desiredRatioTeachers}
                            onChange={(e) => setDesiredRatioTeachers(e.target.valueAsNumber)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-desiredRatioChildrens">
                            Desired Ratio Children
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-desiredRatioChildrens"
                            placeholder="Desired Ratio Childrens"
                            type="number"
                            disabled={isDisabled}
                            value={desiredRatioChildrens}
                            onChange={(e) => setDesiredRatioChildrens(e.target.valueAsNumber)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-personOnline">
                            Person Online
                          </label>
                          <Input type="select" onChange={(e) => setPersonOnline(e.target.value)} value={personOnline}>
                            <option value="PERSON" key={`filter-button-person`}>
                              Person
                            </option>
                            <option value="ONLINE" key={`filter-button-online`}>
                              Online
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <Row>
                            <Col>
                              <label className="form-control-label">Infant Room</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  onChange={(e) => setIsInfantRoom(e.target.checked)}
                                  checked={isInfantRoom}
                                >
                                  <option className="custom-toggle-slider rounded-circle" />
                                </Input>
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">Cameras</h6>
                    <Row>
                      <Col>
                        {cameras.map((c, i) => (
                          <Col key={`new-camera-${i}`}>
                            <Row>
                              <Col className="mb-2" lg="10">
                                <Input value={c} disabled />
                              </Col>
                              <Col className="mb-2" lg="2">
                                {' '}
                                <i
                                  className="far fa-trash-alt text-danger mt-3"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => deleteCamera(i)}
                                ></i>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                        {!showAddCamera && (
                          <Col>
                            <Button
                              size="sm"
                              onClick={() => {
                                setShowAddCamera(true);
                              }}
                            >
                              + Add Camera
                            </Button>
                          </Col>
                        )}
                        {showAddCamera && (
                          <Col>
                            <FormGroup className="mb-1">
                              <label className="form-control-label">URL</label>
                              <Input onChange={(e) => setNewCameraUrl(e.target.value)} />
                            </FormGroup>
                            <Button
                              color="primary"
                              size="sm"
                              className="mb-2"
                              onClick={(e) => {
                                e.preventDefault();
                                setCameras([...cameras, newCameraUrl]);
                                setShowAddCamera(false);
                              }}
                            >
                              Add
                            </Button>
                            <Button
                              color="secondary"
                              size="sm"
                              className="mb-2"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowAddCamera(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </Col>
                        )}
                      </Col>
                    </Row>
                    {id && <ClassPriceSheetList classId={id} />}
                    {id && <ClassIpadAccess data={classIpadAccessFormData} setData={setClassIpadAccessFormData} />}
                  </div>
                  <div className="pl-lg-4">
                    <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                      Save
                    </Button>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ClassCreate;

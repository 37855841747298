import { Spinner } from 'reactstrap';
import { useContext, useEffect, useState } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';
import QrCodeService from '../../services/QrCode';

const QrCode = () => {
  const { token } = useContext(AuthContext);
  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    const getQrCode = async () => {
      if (token) {
        const result = await QrCodeService().get(token);
        setQrCode(result.qr);
      }
    };
    getQrCode();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="text-center w-100">
      {qrCode && <img alt={`QRCode`} src={qrCode} style={{ width: 350, height: 350 }} />}
      {!qrCode && <Spinner />}
    </div>
  );
};

export default QrCode;

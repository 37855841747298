/* eslint-disable max-lines */
import { useContext, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import UserSettingsService from '../../../services/UserSettings';
import SchoolService from '../../../services/School';
import UserCustomer from '../../../services/UserCustomer';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import BillingParentSavePayment from './BillingParentSavePayment';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import PaymentDataStatus from './PaymentDataStatus';
import { Elements } from '@stripe/react-stripe-js';
import confirmAlert from '../../../helpers/confirmAlert';

type BillingParentsModalProps = {
  isOpen: boolean;
  id: string;
  name: string;
  email: string;
  toggleModal: (e?: any) => void;
};

const BillingParentsModal = ({ isOpen, id, name, email, toggleModal }: BillingParentsModalProps) => {
  const { token, currentSchool }: any = useContext(AuthContext);
  const intentClientSecret = new URLSearchParams(window?.location?.search).get('setup_intent_client_secret');
  const [stripePromise, setStripePromise] = useState<any>();

  const [options, setOptions] = useState({} as any);
  const [achMethods, setAchMethods] = useState([] as any[]);
  const [cardMethods, setCardMethods] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [defaultPayment, setDefaultPayment] = useState('ACH');
  const [achData, setACHData] = useState({} as any);
  const [cardData, setCardData] = useState({} as any);
  const [modalPayment, setModalPayment] = useState(false);
  const [transactionType, setTransactionType] = useState('');

  const getSchoolStripeCurrent = async () => {
    if (!currentSchool?.value) return;
    try {
      const r = await SchoolService('').getSchoolStripeCurrent(currentSchool?.value);
      if (r?.data?.stripePublicKey) {
        setStripePromise(loadStripe(r?.data?.stripePublicKey || '', { locale: 'en' }));
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Stripe current error!');
      console.error(message);
    }
  };

  useEffect(() => {
    getSchoolStripeCurrent();
  }, [currentSchool]);

  useEffect(() => {
    if (isOpen) {
      getUserSettings();
      getUserCustomer();
    }
  }, [isOpen]);

  const getUserCustomer = async () => {
    if (!currentSchool?.value) return;
    try {
      setLoading(true);
      const result = await UserCustomer(token).create({
        userId: id || '',
        schoolId: currentSchool?.value || '',
        options: { email: email || '' },
      });
      const customer = result?.data || {};
      setOptions({ customerId: customer?.options?.providerId || '' });
      const ach = customer?.options?.paymentMethods?.ACH || [];
      setAchMethods(ach);
      const cards = customer?.options?.paymentMethods?.CREDIT_CARD || [];
      setCardMethods(cards);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getUserSettings = async () => {
    setLoading(true);
    const settings = await UserSettingsService(token).show(id);
    if (settings?.data) {
      setDefaultPayment(settings?.data?.defaultPayment);
      setACHData(settings?.data?.achData || {});
      setCardData(settings?.data?.cardData || {});
    }
    setLoading(false);
  };

  const setUserSettings = async () => {
    setLoading(true);
    await UserSettingsService(token).createOrUpdate({
      userId: id || '',
      defaultPayment,
      achData,
      cardData,
    });
    getUserSettings();
    toast.success('Settings updated successfully!');
    setLoading(false);
  };

  const togglePaymentModal = (e: any) => {
    e.preventDefault();
    setModalPayment(!modalPayment);
  };

  const removeAlert = async (e: any, paymentMethodId: string) => {
    e.preventDefault();
    return confirmAlert({
      title: 'Delete',
      messageType: 'DELETE',
      message: 'Are you sure to delete this item?',
      onClickYes: () => removePaymentMethod(paymentMethodId),
    });
  };

  const removePaymentMethod = async (paymentMethodId: string) => {
    setLoading(true);
    await UserCustomer(token).deletePaymentMethod(id, currentSchool?.value || '', paymentMethodId);
    toast.success('Payment method deleted successfully!');
    setLoading(false);
    getUserCustomer();
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="lg" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">{name}</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <span>Default payment method</span>
          <Row className="mt-2">
            <Col md="6">
              <FormGroup check>
                <Label check>
                  <Input
                    name="defaultPayment"
                    type="radio"
                    value={'ACH'}
                    checked={defaultPayment === 'ACH'}
                    onChange={(e) => setDefaultPayment(e?.target?.value)}
                  />{' '}
                  ACH
                </Label>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup check>
                <Label check>
                  <Input
                    name="defaultPayment"
                    type="radio"
                    value={'CARD'}
                    checked={defaultPayment === 'CARD'}
                    onChange={(e) => setDefaultPayment(e?.target?.value)}
                  />{' '}
                  Card
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <hr className="mb-2" />
          <Row>
            <Col md="6" className="text-center">
              <h5>Stored Accounts</h5>
              {achMethods?.length ? (
                achMethods.map((a, index) => (
                  <div key={`ach-data-${index}`}>
                    <h3 className="mt-1">{a?.us_bank_account?.bank_name || '-'}</h3>
                    <p>{a?.billing_details?.name || '-'}</p>
                    <p>{a?.us_bank_account?.last4 ? `********${a?.us_bank_account?.last4}` : '-'}</p>
                    <Label check className="mb-2">
                      <Input
                        name="default-ach"
                        type="radio"
                        value={a?.id}
                        checked={a?.id === achData?.defaultId}
                        onChange={(e) => setACHData({ defaultId: e?.target?.value })}
                      />{' '}
                      Is default?
                    </Label>
                    <br />
                    <Button
                      color="danger"
                      size="sm"
                      onClick={(e) => removeAlert(e, a?.id || '')}
                      disabled={achMethods?.length == 1}
                    >
                      <i className="far fa-trash-alt" title="Delete"></i> Delete
                    </Button>
                    <hr className="mb-0 mt-2" />
                  </div>
                ))
              ) : (
                <Col>There are no saved accounts</Col>
              )}

              <br />
              <Button
                color="primary"
                size="md"
                onClick={() => {
                  setTransactionType('ACH');
                  setModalPayment(true);
                }}
              >
                Add Account
              </Button>
            </Col>
            <Col md="6" className="text-center">
              <h5>Stored Cards</h5>
              {cardMethods?.length ? (
                cardMethods.map((a, index) => (
                  <div key={`card-data-${index}`}>
                    <h3 className="mt-1">{a?.card?.brand ? String(a?.card?.brand).toUpperCase() : '-'}</h3>
                    <p>
                      {a?.card?.exp_month && a?.card?.exp_year ? `${a?.card?.exp_month}/${a?.card?.exp_year}` : '-'}
                    </p>
                    <p>{a?.card?.last4 ? `********${a?.card?.last4}` : '-'}</p>
                    <Label check className="mb-2">
                      <Input
                        name="default-card"
                        type="radio"
                        value={a?.id}
                        checked={a?.id === cardData?.defaultId}
                        onChange={(e) => setCardData({ defaultId: e?.target?.value })}
                      />{' '}
                      Is default?
                    </Label>
                    <br />
                    <Button
                      color="danger"
                      size="sm"
                      onClick={(e) => removeAlert(e, a?.id || '')}
                      disabled={cardMethods?.length == 1}
                    >
                      <i className="far fa-trash-alt" title="Delete"></i> Delete
                    </Button>
                    <hr className="mb-0 mt-2" />
                  </div>
                ))
              ) : (
                <Col>There are no saved credit cards</Col>
              )}
              <br />
              <Button
                color="primary"
                size="md"
                onClick={() => {
                  setTransactionType('CREDIT_CARD');
                  setModalPayment(true);
                }}
              >
                Add Card
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md="6 text-center" xs="6">
              <Button
                color="secondary"
                size="md"
                style={{ minWidth: '100px' }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col md="6 text-center" xs="6">
              <Button
                color="success"
                size="md"
                style={{ minWidth: '100px' }}
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  setUserSettings();
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
        {modalPayment && stripePromise && (
          <BillingParentSavePayment
            isOpen={modalPayment}
            toggleModal={togglePaymentModal}
            customerOptions={options}
            stripePromise={stripePromise}
            id={id}
            name={name}
            email={email}
            transactionType={transactionType}
          />
        )}

        {intentClientSecret && stripePromise && (
          <Elements stripe={stripePromise} options={options}>
            <PaymentDataStatus clientSecret={intentClientSecret || ''} />
          </Elements>
        )}
      </Modal>
    </>
  );
};

export default BillingParentsModal;

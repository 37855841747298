import { Row, Col, Card, CardBody, Input, FormGroup, InputGroup } from 'reactstrap';
import { useContext, useEffect, useState } from 'react';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TimelineService from '../../../../services/Timeline';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import TimeLineItem from '../../../../components/Timeline/TimelineItem';
import Gallery from '../../../../components/Gallery/ImageViewer';
import activityIcons from '../../Timeline/timelineActivityIcons';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import UserService from '../../../../services/User';
import { isMobile } from 'react-device-detect';

const TimelineTab = ({ kidsIdProp }: { kidsIdProp?: string }) => {
  const { id: kidsIdParam } = useParams<{ id: string }>();
  const kidsId = kidsIdProp || kidsIdParam;
  const { token }: any = useContext(AuthContext);
  const [data, setData] = useState<{}>({});
  const [timelineFilter, setTimelineFilter] = useState('');
  const [startDateRange, setStartDateRange] = useState('');
  const [endDateRange, setEndDateRange] = useState('');
  const [authUser, setAuthUser] = useState<any>({});
  useEffect(() => {
    getByKidsId();
    getAuthUserData();
  }, [timelineFilter, startDateRange, endDateRange, kidsId]);

  const getAuthUserData = async () => {
    try {
      const result = await UserService(token).profile();
      if (result?.data) {
        setAuthUser(result?.data);
      }
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  const getByKidsId = async () => {
    try {
      let options = '?';

      if (timelineFilter) {
        options = options.concat(`type=${timelineFilter}&`);
      }

      if (startDateRange && moment(startDateRange).isValid()) {
        options = options.concat(`initialDate=${moment(startDateRange).utc().startOf('day').format()}&`);
      }

      if (endDateRange && moment(endDateRange).isValid()) {
        options = options.concat(`endDate=${moment(endDateRange).utc().endOf('day').format()}&`);
      }
      const result = await TimelineService(token).showByStudentProfile(kidsId, options);
      const dataResult: any = result.data || [];
      const data = dataResult
        .filter((d: any) => d.kidsId === kidsId)
        .reduce(function (rv: any, x: any) {
          (rv[moment(x.time).format('MM/DD/YYYY')] = rv[moment(x.time).format('MM/DD/YYYY')] || []).push(x);
          return rv;
        }, {});
      setData(data);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid Kids ID');
    }
  };

  return (
    <Row>
      <Col className="order-xl-2" xl="12" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
        <Row>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-validAt">
                Timeline Filter
              </label>
              <Input type="select" onChange={(event) => setTimelineFilter(event.target.value)} value={timelineFilter}>
                <option value="">All</option>
                <option value="checkin">Check In</option>
                <option value="moving">Moving</option>
                <option value="activity">Activity</option>
                <option value="food">Food</option>
                <option value="sleep">Sleep</option>
                <option value="bathroom">Bathroom</option>
                <option value="reminder">Reminders</option>
                <option value="checkout">Check Out</option>
              </Input>
            </FormGroup>
          </Col>

          <Col lg="4" xs="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-validAt">
                Initial Date
              </label>
              <InputGroup className="input-group-alternative">
                <ReactDatetime
                  inputProps={{
                    placeholder: 'Initial Date',
                  }}
                  onChange={(newValue) => {
                    if (moment(`${newValue}`).isValid()) {
                      setStartDateRange(`${newValue}`);
                    } else {
                      setStartDateRange('');
                    }
                  }}
                  timeFormat={false}
                  dateFormat={'MM/DD/yyyy'}
                  closeOnSelect={true}
                />
              </InputGroup>
            </FormGroup>
          </Col>

          <Col lg="4" xs="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-validAt">
                End Date
              </label>
              <InputGroup className="input-group-alternative">
                <ReactDatetime
                  inputProps={{
                    placeholder: 'End Date',
                  }}
                  onChange={(newValue) => {
                    if (moment(`${newValue}`).isValid()) {
                      setEndDateRange(`${newValue}`);
                    } else {
                      setEndDateRange('');
                    }
                  }}
                  timeFormat={false}
                  dateFormat={'MM/DD/yyyy'}
                  closeOnSelect={true}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        <Card className="bg-secondary shadow border-0">
          <CardBody className="pb-4" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
            <Row>
              {Object.keys(data)?.length ? (
                <Col lg="12">
                  <h3 className="text-muted ml-4 text-uppercase">Timeline</h3>
                  <VerticalTimeline layout="1-column-left" lineColor="#a9bfd6" animate={false}>
                    {Object.keys(data).map((grouped: string) => (
                      <>
                        <div className="text-center">
                          <h2>
                            <span className="badge badge-primary badge-lg">{grouped}</span>
                          </h2>
                        </div>
                        {(data as any)[grouped].map((a: any, index: number) => {
                          const findItem = activityIcons.find((item) => a.kidsActivity.tags.includes(item.key));
                          const icon = findItem?.icon || 'fa-ellipsis';
                          const status = findItem?.status || 'bg-info';
                          return (
                            <>
                              <TimeLineItem
                                icon={icon}
                                status={status}
                                name={a.kidsActivity.name}
                                description={(a as any).notes}
                                key={`item-${index}`}
                                canDownloadAttachs={
                                  authUser.configs?.canDownloadPhotos === undefined
                                    ? true
                                    : authUser.configs?.canDownloadPhotos
                                }
                                attachments={a.attachments}
                                {...a}
                                content={
                                  a.attachments.length > 0 && (
                                    <div className="d-flex flex-column">
                                      <p
                                        style={{ fontSize: '10px' }}
                                        className="h6 mt-4 text-muted font-weight-600 text-uppercase mb-3"
                                      >
                                        Attachments
                                      </p>
                                      <Gallery galleries={a.attachments} />
                                    </div>
                                  )
                                }
                              />
                            </>
                          );
                        })}
                      </>
                    ))}
                  </VerticalTimeline>
                </Col>
              ) : (
                <Col lg="12">
                  <h3 className="text-muted ml-4 text-uppercase">Timeline</h3>
                  <br />
                  <div className="vertical-timeline vertical-timeline--one-column-left" style={{ textAlign: 'center' }}>
                    <span>No data found</span>
                  </div>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default TimelineTab;

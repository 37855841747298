import { Card, Button, Container, Row, Col, CardBody, FormGroup, Form, ButtonGroup, Input } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import KidsParents from '../../../services/KidsParents';
import User from '../../../services/User';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import { createPagedMeta } from '../../../entities/PagedMeta';
import UserInterface from '../../../interfaces/UserInterface';
import NumberFormat from 'react-number-format';

type KidsIdProps = {
  kidsId: string;
  kidsParent?: any;
  newContactCreated?: any;
  onModalStatus: () => void;
  setIsContactOpen: (isOpen: boolean) => void;
};

const KidsParentsForm = ({ kidsId, kidsParent, newContactCreated, onModalStatus, setIsContactOpen }: KidsIdProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isPaying, setIsPaying] = useState(kidsParent?.isPaying || false);
  const [payPercent, setPayPercent] = useState(kidsParent?.payPercent || 0);
  const [title, setTitle] = useState(kidsParent?.title || '');
  const [userId, setUserId] = useState(kidsParent?.userId || '');
  const [userSelected, setUserSelected] = useState(
    kidsParent?.user
      ? {
          label: `${kidsParent?.user?.firstName} ${kidsParent?.user?.lastName}`,
          value: kidsParent?.user?.id,
          name: `${kidsParent?.user?.firstName} ${kidsParent?.user?.lastName}`,
          photo: kidsParent?.user?.picture,
        }
      : undefined,
  );

  const changeonModalStatus = () => {
    onModalStatus();
  };

  useEffect(() => {
    listSelectUser();
    if (newContactCreated?.id) {
      handleUserChange({
        name:
          newContactCreated?.firstName && newContactCreated?.lastName
            ? `${newContactCreated?.firstName} ${newContactCreated?.lastName}`
            : '',
        photo: newContactCreated?.picture,
        value: newContactCreated?.id,
        label: `${newContactCreated?.firstName} ${newContactCreated?.lastName}`,
      });
    }
  }, [kidsId, newContactCreated]);

  const listSelectUser = async (search?: string) => {
    try {
      const filteredOptions = {
        filters: [] as any[],
        options: createOptionsPage(createPagedMeta()),
      };
      if (search) {
        const filterFirstName = {
          field: 'firstName',
          operation: 'ilike',
          value: `%${search}%`,
        };
        filteredOptions.filters = [filterFirstName];
      }
      const resultFirst = await User(token).list(filteredOptions);
      const dataFirst: UserInterface[] = resultFirst.data;
      const optionsFirst = dataFirst.map(({ id, firstName, lastName, picture }: UserInterface) => ({
        value: id,
        label: firstName && lastName ? `${firstName} ${lastName}` : '',
        name: firstName && lastName ? `${firstName} ${lastName}` : '',
        photo: picture,
      }));

      if (search) {
        const filterLastName = {
          field: 'lastName',
          operation: 'ilike',
          value: `%${search}%`,
        };
        filteredOptions.filters = [filterLastName];
      }
      const resultLast = await User(token).list(filteredOptions);
      const dataLast: UserInterface[] = resultLast.data;
      const optionsLast = dataLast.map(({ id, firstName, lastName, picture }: UserInterface) => ({
        value: id,
        label: firstName && lastName ? `${firstName} ${lastName}` : '',
        name: firstName && lastName ? `${firstName} ${lastName}` : '',
        photo: picture,
      }));
      const uniqueParents = [...optionsFirst, ...optionsLast].filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          [...optionsFirst, ...optionsLast].findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      return [...new Set(uniqueParents)];
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'No users found');
      return [];
    }
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    const body = {
      kidsId,
      userId,
      title,
      isPaying,
      payPercent: parseFloat(payPercent),
    };

    try {
      const result = kidsParent?.id
        ? await KidsParents(token).update(kidsId, kidsParent?.id || '', body)
        : await KidsParents(token).create(body);

      if (result?.data?.id) {
        onModalStatus();
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Save error!');
      toast.error(message);
    }

    setLoading(false);
    e.preventDefault();
  };

  const handleUserChange = (user: any) => {
    setUserSelected(user);
    setUserId(user.value);
  };

  const renderResult = (option: any) => {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10,
    };
    return (
      <div className="select-option">
        <button type="button" style={{ border: 0, width: '100%', background: 'transparent' }}>
          <div style={{ width: '100%', textAlign: 'left', display: 'inline-block' }}>
            <span>
              <img alt="" style={imgStyle} width="28" height="28" src={option.photo} />
              <span>{option.name}</span>
            </span>
          </div>
        </button>
      </div>
    );
  };

  return (
    <>
      <Container fluid>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            <CardBody>
              <Row>
                <Col lg="12">
                  <FormGroup style={{ zIndex: 999999999 }}>
                    <label className="form-control-label" htmlFor="input-franchisee-id">
                      Contact
                    </label>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={listSelectUser}
                      defaultOptions
                      onChange={handleUserChange}
                      value={userSelected}
                      formatOptionLabel={renderResult}
                    />
                    <div style={{ textAlign: 'right', width: '100%', color: '#8898aa', fontSize: 14 }}>
                      <span>
                        Not on the list?{' '}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsContactOpen(true);
                            // onModalStatus();
                          }}
                        >
                          Create new
                        </a>
                      </span>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Type</label>
                    <ButtonGroup style={{ zIndex: 0 }}>
                      <Button color="primary" outline onClick={() => setTitle('Parent')} active={title === 'Parent'}>
                        Parent
                      </Button>
                      <Button
                        color="primary"
                        outline
                        onClick={() => setTitle('Guardian')}
                        active={title === 'Guardian'}
                      >
                        Guardian
                      </Button>
                      <Button
                        color="primary"
                        outline
                        onClick={() => setTitle('Pickup Authorized')}
                        active={title === 'Pickup Authorized'}
                      >
                        Pickup Authorized
                      </Button>
                    </ButtonGroup>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-0">
                    <Row>
                      <Col>
                        <label className="form-control-label" htmlFor="input-enabledTour">
                          Is Payer?
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="custom-toggle">
                          <Input type="checkbox" onChange={(e) => setIsPaying(e.target.checked)} checked={isPaying} />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                {isPaying && (
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label">Percentage</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        suffix="%"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={payPercent}
                        onValueChange={(e: any) => setPayPercent(e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>

              <Row className="mt-5">
                <Col md="6 text-center" xs="6">
                  <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                    Save
                  </Button>
                </Col>
                <Col md="6 text-center" xs="6">
                  <Button color="secondary" size="lg" style={{ minWidth: '100px' }} onClick={changeonModalStatus}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default KidsParentsForm;

import { Container, Badge, Button, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import PromoCodesService from '../../../services/PromoCodes';
import PromoCodesInterface, { validStatus, statusEnum } from '../../../interfaces/PromoCodesInterface';
import { FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import DataTable from '../../../components/Core/DataTable';
import isAdmin from '../../../helpers/isAdmin';
import isUpperManagment from '../../../helpers/isUpperManagment';

const PromoCodesList = () => {
  const { token, user, currentSchool } = useContext(AuthContext);
  const hasEditPermission = isAdmin(user) || isUpperManagment(user);
  const columns = [
    {
      name: 'Code',
      route: '/promo-codes',
      label: (row: any) => row.code,
      disabled: !hasEditPermission,
    },
    {
      name: 'Reg Fee',
      selector: (row: any) => row.regFee,
      grow: 1,
      cell: (row: any) => <span>{`${Number(row.regFee).toFixed(2)}%`}</span>,
    },
    {
      name: 'Tuition Fee',
      selector: (row: any) => row.tuitionFee,
      grow: 1,
      cell: (row: any) => <span>{`${Number(row.tuitionFee).toFixed(2)}%`}</span>,
    },
    {
      name: 'Qty',
      selector: (row: any) => row.qty,
      grow: 1,
    },
    {
      name: 'Used',
      selector: (row: any) => row.used,
      grow: 1,
    },
    {
      name: 'Status',
      selector: (row: any) => row.status,
      grow: 1,
      cell: (row: any) => (
        <Badge
          color=""
          className="badge-dot badge-lg"
          style={{ fontSize: '0.8125rem', verticalAlign: 'middle', lineHeight: 'normal' }}
        >
          <i
            className={`bg-${validStatus[row.status as statusEnum].color} mr-2`}
            style={{ verticalAlign: 'baseline' }}
          />
          {validStatus[row.status as statusEnum].value}
        </Badge>
      ),
    },
    hasEditPermission && {
      type: 'delete',
      onClick: (row: any) => {
        deleteItemAlert(row.id);
      },
    },
  ];

  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [
      {
        field: 'status',
        operation: '=',
        value: 'ACTIVE',
      },
    ],
    options: createOptionsPage(pagedMeta),
  });

  useEffect(() => {
    filterPromoCodes('active');
  }, [filteredOptions, currentSchool]);

  const loadList = async (filteredOptions: FilteredOptionsInterface, status: string = 'active') => {
    try {
      setLoading(true);
      let result;
      const newFilteredOptions = JSON.parse(JSON.stringify(filteredOptions));
      newFilteredOptions?.filters?.push({
        field: 'schoolId',
        operation: 'hash',
        value: currentSchool?.value,
      });
      if (status === 'active') {
        result = await PromoCodesService(token || '').listActive(newFilteredOptions, currentSchool?.value);
      } else {
        result = await PromoCodesService(token || '').listInactive(newFilteredOptions);
      }
      const data: PromoCodesInterface[] = result.data;
      setData(data);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const deleteItemAlert = async (id: string) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Container fluid>
            <Card>
              <CardBody>
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  Delete
                </CardTitle>
                <Row className="mt-3">
                  <Col md="12 text-center">
                    <span className="h2 font-weight-bold mb-0">{`Are you sure to delete item: ${id}?`}</span>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md="6 text-center">
                    <Button
                      color="primary"
                      href="#"
                      onClick={() => {
                        deleteItem(id);
                        onClose();
                      }}
                      size="lg"
                      style={{ minWidth: '100px' }}
                    >
                      Yes
                    </Button>
                  </Col>
                  <Col md="6 text-center">
                    <Button color="danger" href="#" onClick={onClose} size="lg" style={{ minWidth: '100px' }}>
                      No
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        );
      },
    });
  };

  const deleteItem = async (id: string) => {
    try {
      setLoading(true);
      await PromoCodesService(token || '').delete(id);
      toast.success(`Deleted successfully!`);
      loadList(filteredOptions);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || `Error deleting item: ${id}`);
      return toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const filterPromoCodes = async (status: string) => {
    const newFilteredOptions = filteredOptions;
    const filters = [
      {
        field: 'status',
        operation: '=',
        value: status.toUpperCase(),
      },
    ];

    newFilteredOptions.filters = filters;

    await loadList(newFilteredOptions, status);
  };

  const status = filteredOptions.filters.find((f: any) => f.field === 'status')?.value.toLowerCase();

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <DataTable
          title="Promo Codes List"
          columns={columns}
          data={data}
          totalRows={pagedMeta.total}
          loading={loading}
          button={hasEditPermission}
          buttonUrl="/promo-codes/new"
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          filtersList={[
            { value: 'active', label: 'Active Promo Code', isActive: status === 'active' },
            { value: 'inactive', label: 'Inactive Promo Code', isActive: status === 'inactive' },
          ]}
          isFilterButtons={true}
          onChangeFilter={(e) => {
            filterPromoCodes(e);
          }}
        />
      </Container>
    </>
  );
};

export default PromoCodesList;

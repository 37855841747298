import { useEffect, useState, useContext } from 'react';
import {
  Card,
  CardBody,
  CardText,
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from 'reactstrap';
import ModalContent from '../../../components/Modals/Modal';
import DataTable from '../../../components/Core/DataTable';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import { createPagedMeta } from '../../../entities/PagedMeta';
import { FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import KidsService from '../../../services/Kids';
import KidsInterface from '../../../interfaces/KidsInterface';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import history from '../../../history';

export const WebEnrollList: React.FC<{}> = () => {
  const { currentSchool = {}, token } = useContext(AuthContext);

  const columnsKids = [
    {
      name: 'Name',
      selector: (row: any) => (
        <Link to={`/kids/${row.id}`}>
          <span>{`${row?.firstName || '-'} ${row?.lastName || '-'}`}</span>
        </Link>
      ),
      grow: 1,
    },
    {
      name: 'Class Type',
      selector: (row: any) => row?.enrollment?.classType?.name,
      grow: 1,
      cell: (row: any) => <span className="mb-0">{row?.enrollment?.classType?.name}</span>,
    },
    {
      name: 'Action',
      selector: (row: any) => row.id,
      grow: 1,
      cell: (row: any) => (
        <UncontrolledDropdown style={{ float: 'right' }}>
          <DropdownToggle href="#" role="button" size="sm" color="primary" onClick={(e) => e.preventDefault()}>
            Actions
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem
              onClick={(e) => {
                e.preventDefault();
                history.push(`/kids/${row.id}`);
              }}
            >
              <Link to={`/kids/${row.id}`}>
                <span>
                  <i className="far fa-check-circle"></i> Approve
                </span>
              </Link>
            </DropdownItem>
            <DropdownItem
              onClick={(e) => {
                e.preventDefault();
                setToggleModalDeny(!toggleModalDeny);
                setKidsId(row.id || '');
              }}
              style={{ color: 'red' }}
            >
              <span>
                <i className="far fa-times-circle"></i> Deny
              </span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const [kidsEnroll, setKidsEnroll] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [pagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  });
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleModalDeny, setToggleModalDeny] = useState(false);
  const [kidsId, setKidsId] = useState('');
  const [denyReason, setDenyReason] = useState('');

  useEffect(() => {
    handleFetch(filteredOptions);
  }, [filteredOptions, currentSchool, toggleModal]);

  const handleFetch = async (filteredOptions: FilteredOptionsInterface) => {
    try {
      setLoading(true);
      const newFilteredOptions = JSON.parse(JSON.stringify(filteredOptions));
      if (!newFilteredOptions?.options) {
        newFilteredOptions.options = createOptionsPage(pagedMeta);
      }
      const filter = {
        field: 'status',
        operation: '=',
        value: 'FOR_APPROVAL',
      };
      if (!Array.isArray(newFilteredOptions?.filters)) {
        newFilteredOptions.filters = [];
      }
      newFilteredOptions.filters.push(filter);
      newFilteredOptions.filters.push({
        field: 'registerOrigin',
        operation: '=',
        value: 'WEBENROLL',
      });
      newFilteredOptions.options.itemsPerPage = 10000;
      const result = await KidsService(token || '').list(newFilteredOptions, currentSchool.value);
      const data: KidsInterface[] = result?.data;

      setKidsEnroll([...data]);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return console.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handleEnrollDeny = async () => {
    if (!kidsId) return;
    try {
      await KidsService(token || '').enrollDeny(kidsId);
      toast.success('Denied successfully');
      handleFetch(filteredOptions);
      setToggleModalDeny(false);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Deny error');
    }
  };

  return (
    <>
      {kidsEnroll?.length ? (
        <Row className="mt-5">
          <Col md="12">
            <Card style={{ backgroundColor: '#F6CED8' }}>
              <CardBody>
                <CardText>
                  <Row>
                    <Col>Web Enroll</Col>
                    <Button size="sm" color="primary" onClick={() => setToggleModal(!toggleModal)}>
                      See All
                    </Button>
                  </Row>
                </CardText>
              </CardBody>
            </Card>

            <ModalContent
              class="p-0"
              modal={toggleModal}
              toggle={() => setToggleModal(!toggleModal)}
              title="Web Enroll"
              size="lg"
            >
              <Card className="bg-secondary shadow">
                <CardBody>
                  <DataTable
                    header={false}
                    shadowStyle={false}
                    columns={columnsKids}
                    data={kidsEnroll}
                    totalRows={pagedMeta.total}
                    loading={loading}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                  />
                </CardBody>
              </Card>
            </ModalContent>

            <ModalContent
              class="p-0"
              modal={toggleModalDeny}
              toggle={() => setToggleModalDeny(!toggleModalDeny)}
              title="Deny Reason"
              size="md"
            >
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-typeTour">
                          Please choose the reason for deny
                        </label>
                        <select
                          name="input-reason"
                          className="form-control"
                          value={denyReason}
                          onChange={(e) => setDenyReason(e.target.value)}
                          required
                        >
                          <option value="">Please select</option>
                          <option value="Not Authorized to Enroll">Not Authorized to Enroll</option>
                          <option value="No Spots Available">No Spots Available</option>
                          <option value="Multiple Enrollments Came Though">Multiple Enrollments Came Though</option>
                          <option value="Parent Changed Mind">Parent Changed Mind</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <Button
                        color="primary"
                        className="waiting-list-btn-delete mb-3"
                        size="sm"
                        onClick={handleEnrollDeny}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </ModalContent>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

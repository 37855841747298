import http from '../helpers/http';
import { FilteredOptionsInterface, makeQueryParamsForRequest } from '../entities/FilteredOptions';
import ClassInterface from '../interfaces/ClassInterface';

const Class = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get('/class', {
        headers,
        params,
      });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`/class/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: Partial<ClassInterface>) => {
      const result = await http.post(`/class`, body, { headers });
      return result.data;
    },
    update: async (id: string, body: Partial<ClassInterface>) => {
      const result = await http.put(`/class/${id}`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/class/${id}`, {
        headers,
      });
      return result.data;
    },
    listBySchool: async (schoolId: string) => {
      const result = await http.get(`/school/${schoolId}/class`, {
        headers,
      });
      return result.data;
    },
    listBySchoolForEnroll: async (schoolId: string) => {
      const result = await http.get(`/school/${schoolId}/class-public`, {
        headers,
      });
      return result.data;
    },
    listAttendances: async (classId: string, relation?: string, attendanceType?: string) => {
      const result = await http.get(
        `/class/${classId}/attendances?${attendanceType ? `type=${attendanceType}` : ''}${
          relation ? `${attendanceType ? '&' : ''}relation=${relation}` : ''
        }`,
        {
          headers,
        },
      );
      return result.data;
    },
  };
};

export default Class;

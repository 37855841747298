import { FormGroup, Input, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import TimePicker from '../../components/Core/TimezonePicker';
import { simpleKidEnroll } from './ModalParentKids';
import { ucfirst } from '../../helpers/string';

type ModalParentKidsFormProps = {
  type: string;
  data?: simpleKidEnroll | undefined;
  setTime: (val: string) => void;
  setTimeDiasper: (val: string) => void;
  time: string;
  timeDiasper: string;
};

const ModalParentKidsForm = ({ type, data, setTime, setTimeDiasper, time, timeDiasper }: ModalParentKidsFormProps) => {
  const howMuchOptions = () => {
    const options = [];
    for (let i = 0; i <= 20; i++) {
      options.push(<option key={`how-much-options-${i}`}>{i}</option>);
    }
    return options;
  };

  return type === 'infant' ? (
    <>
      <input
        type="hidden"
        name="kids_activity_id_food"
        defaultValue={data?.data.find((item: any) => item.activityType === 'Food')?.id}
      />
      <input
        type="hidden"
        name="kids_activity_id_diaper"
        defaultValue={data?.data.find((item: any) => item.activityType === 'Diaper')?.id}
      />
      <div className="col-7">
        <FormGroup className="text-center">
          <label className="form-control-label" htmlFor="input-school-id">
            When Was Last Feeding:
          </label>
          <TimePicker
            onChangeTime={(time: string) => {
              setTime(time);
            }}
            minuteStep={5}
            defaultValue={time || undefined}
            name="when_was_last_feeding"
            disabled={data?.data && !data.editing}
          />
        </FormGroup>
      </div>
      <div className="col-7">
        <FormGroup className="text-center">
          <label className="form-control-label" htmlFor="input-school-id">
            How Much?:
          </label>
          <InputGroup className={`${data?.data && !data.editing ? 'disabled' : ''}`}>
            <Input
              type="select"
              className="text-center"
              required
              name="how_much"
              onChange={() => {}}
              defaultValue={parseInt(data?.data.find((item: any) => item.activityType === 'Food')?.childAte || 0)}
              disabled={data?.data && !data.editing}
            >
              {howMuchOptions()}
            </Input>
            <InputGroupAddon addonType="append">oz</InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </div>
      <div className="col-7">
        <FormGroup className="text-center">
          <label className="form-control-label" htmlFor="input-school-id">
            Last Diaper Change:
          </label>
          <TimePicker
            onChangeTime={(time: string) => {
              setTimeDiasper(time);
            }}
            minuteStep={5}
            defaultValue={timeDiasper || undefined}
            name="when_was_last_feeding"
            disabled={data?.data && !data.editing}
          />
          <div className="row">
            {['wet', 'bm', 'both'].map((value: string, i: number) => (
              <div className="col" key={`deaper_change_expecification_${i}`}>
                <FormGroup check>
                  <Label check>
                    <Input
                      name="deaper_change_expecification"
                      type="radio"
                      value={value}
                      onChange={() => {}}
                      defaultChecked={
                        data?.data.find((item: any) => item.activityType === 'Diaper').options[0] === value
                      }
                      disabled={data?.data && !data.editing}
                    />
                    {value === 'bm' ? 'BM' : ucfirst(value)}
                  </Label>
                </FormGroup>
              </div>
            ))}
          </div>
        </FormGroup>
      </div>
      <div className="col-7">
        <FormGroup className="text-center">
          <label className="form-control-label" htmlFor="input-school-id">
            Anything Else We Need To Know:
          </label>
          <textarea
            className="form-control"
            name="more_detail"
            defaultValue={data?.data.find((item: any) => item.activityType === 'Food').describe}
            disabled={data?.data && !data.editing}
          />
        </FormGroup>
      </div>
    </>
  ) : (
    <>
      <input type="hidden" name="kids_activity_id" defaultValue={data?.data[0].id} />
      <div className="col-7">
        <FormGroup className="text-center">
          <label className="form-control-label" htmlFor="input-school-id">
            Did Your Child Have Breakfast?
          </label>
          <div className="row">
            {['yes', 'no/barely'].map((value: string, i: number) => (
              <div className="col" key={`option-have-breakfast_${i}`}>
                <FormGroup check>
                  <Label check>
                    <Input
                      name="have_breakfast"
                      type="radio"
                      value={value}
                      onChange={() => {}}
                      defaultChecked={data?.data[0].have_breakfast === value}
                      disabled={data?.data && !data.editing}
                    />
                    {ucfirst(value) === 'No/barely' ? 'No/Barely' : ucfirst(value)}
                  </Label>
                </FormGroup>
              </div>
            ))}
          </div>
        </FormGroup>
      </div>
      <div className="col-7">
        <FormGroup className="text-center">
          <label className="form-control-label" htmlFor="input-school-id">
            Did You Child Sleep Well?
          </label>
          <div className="row">
            {['yes', 'no'].map((value: string, i: number) => (
              <div className="col" key={`option-sleep-well_${i}`}>
                <FormGroup check>
                  <Label check>
                    <Input
                      name="sleep_well"
                      type="radio"
                      value={value}
                      onChange={() => {}}
                      defaultChecked={data?.data[0].sleep_well === value}
                      disabled={data?.data && !data.editing}
                    />
                    {ucfirst(value)}
                  </Label>
                </FormGroup>
              </div>
            ))}
          </div>
        </FormGroup>
      </div>
      <div className="col-7">
        <FormGroup className="text-center">
          <label className="form-control-label" htmlFor="input-school-id">
            Anything Else We Need To Know:
          </label>
          <textarea
            className="form-control"
            name="more_detail"
            defaultValue={data?.data[0].more_detail}
            disabled={data?.data && !data.editing}
          />
        </FormGroup>
      </div>
    </>
  );
};
export default ModalParentKidsForm;

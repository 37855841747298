/* eslint-disable max-lines */
import { useEffect, useState, useContext } from 'react';
import {
  Card,
  CardBody,
  CardText,
  Row,
  Col,
  Button,
  Badge,
  Nav,
  NavItem,
  NavLink,
  CardHeader,
  TabContent,
  TabPane,
} from 'reactstrap';
import ModalContent from '../../../components/Modals/Modal';
import DataTable from '../../../components/Core/DataTable';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import { FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import KidsService from '../../../services/Kids';
import EmployeeService from '../../../services/Employee';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import isAdmin from '../../../helpers/isAdmin';

export const Expirations: React.FC<{}> = () => {
  const { currentSchool = {}, token, user } = useContext(AuthContext);

  const statusColors: any = {
    PENDING: 'danger',
    EXPIRED: 'warning',
    UPCOMING: 'info',
  };

  const columnsKids = [
    {
      name: 'Child',
      route: (row: any) => `/kids/${row.kidsId}`,
      label: (row: any) => <span>{`${row?.kidsName || '-'}`}</span>,
      grow: 2,
    },
    {
      name: 'Type',
      selector: (row: any) => row.type,
      grow: 1,
      cell: (row: any) => <span className="mb-0">{row.type}</span>,
    },
    {
      name: 'Expiration Date',
      selector: (row: any) => `${row?.expirationDate ? toLocaleFormatSlash(row?.expirationDate) : '-'}`,
      grow: 2,
    },
    {
      name: 'Status',
      selector: (row: any) => row.status,
      grow: 1,
      sortable: true,
      cell: (row: any) => {
        return (
          <Badge className="cursor-pointer" color={statusColors[row.status]}>
            {row.status}
          </Badge>
        );
      },
    },
  ];

  const columnsUsers = [
    {
      name: 'User',
      route: (row: any) => `/users/${row?.id}/profile`,
      label: (row: any) => <span>{`${row?.firstName || ''} ${row?.lastName || ''}`}</span>,
      grow: 2,
    },
    {
      name: 'Type',
      selector: (row: any) => row?.expirationType,
      grow: 1,
      cell: (row: any) => <span className="mb-0">{row?.expirationType}</span>,
    },
    {
      name: 'Expiration Date',
      selector: (row: any) => `${row?.expDate ? toLocaleFormatSlash(row?.expDate) : '-'}`,
      grow: 1,
    },
    {
      name: 'Status',
      selector: (row: any) => row.status,
      grow: 1,
      sortable: true,
      cell: (row: any) => {
        return (
          <Badge className="cursor-pointer" color={statusColors[row.status]}>
            {row.status}
          </Badge>
        );
      },
    },
  ];

  const [expirationKids, setExpirationKids] = useState([] as any[]);
  const [expirationUsers, setExpirationUsers] = useState([] as any[]);
  const [kidsSortBy, setKidsSortBy] = useState('');
  const [usersSortBy, setUsersSortBy] = useState('');
  const [loading, setLoading] = useState(false);
  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  });
  const [toggleModal, setToggleModal] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
    },
  };

  useEffect(() => {
    handleFetch(filteredOptions);
    handleFetchUsers();
  }, [filteredOptions, currentSchool]);

  useEffect(() => {
    if (kidsSortBy) {
      const kids = JSON.parse(JSON.stringify(expirationKids));
      const newKids = [];
      for (const k of kids) {
        if (k.status === kidsSortBy) {
          newKids.push(k);
        }
      }
      for (const k of kids) {
        if (k.status !== kidsSortBy) {
          newKids.push(k);
        }
      }
      setExpirationKids(newKids);
    }
  }, [kidsSortBy]);

  useEffect(() => {
    if (usersSortBy) {
      const users = JSON.parse(JSON.stringify(expirationUsers));
      const newUsers = [];
      for (const u of users) {
        if (u.status === usersSortBy) {
          newUsers.push(u);
        }
      }
      for (const u of users) {
        if (u.status !== usersSortBy) {
          newUsers.push(u);
        }
      }
      setExpirationUsers(newUsers);
    }
  }, [usersSortBy]);

  const handleFetch = async (filteredOptions: FilteredOptionsInterface) => {
    try {
      setLoading(true);

      filteredOptions.filters.push({
        field: 'status',
        operation: '=',
        value: 'ACTIVE',
      });
      const result = await KidsService(token || '').list(filteredOptions, currentSchool.value);
      const data: any[] = result?.data;
      const kidsData = [];
      for (const k of data) {
        const dataDefault: any = {
          kidsId: k.id,
          kidsName: `${k?.firstName || ''} ${k?.lastName || ''}`,
        };
        if (k?.immunization?.pending?.length) {
          for (const p of k?.immunization?.pending) {
            kidsData.push({
              ...dataDefault,
              type: p.name || '',
              expirationDate: p.validAt,
              status: 'PENDING',
            });
          }
        }
        if (k?.immunization?.expired?.length) {
          for (const p of k?.immunization?.expired) {
            kidsData.push({
              ...dataDefault,
              type: p.name || '',
              expirationDate: p.validAt,
              status: 'EXPIRED',
            });
          }
        }
        if (k?.immunization?.upcoming?.length) {
          for (const p of k?.immunization?.upcoming) {
            kidsData.push({
              ...dataDefault,
              type: p.name || '',
              expirationDate: p.validAt,
              status: 'UPCOMING',
            });
          }
        }
      }
      setExpirationKids(kidsData);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return console.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchUsers = async () => {
    if (!currentSchool?.value) return;
    try {
      setLoading(true);

      const result = await EmployeeService(token || '').listBySchool(currentSchool.value);
      const data: any[] = result?.data || [];
      const newData = data.filter((u) => u?.user?.expirationDates?.some((e: any) => e.status));
      const usersData = [];
      for (const n of newData) {
        if (n?.user?.expirationDates) {
          for (const e of n?.user?.expirationDates) {
            usersData.push({
              ...e,
              id: n?.user?.id,
              firstName: n?.user?.firstName,
              lastName: n?.user?.lastName,
            });
          }
        }
      }
      setExpirationUsers(usersData);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return console.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  return expirationKids?.length || expirationUsers?.length ? (
    <>
      <Card style={{ backgroundColor: '#F6CED8' }}>
        <CardBody>
          <CardText>
            <Row>
              <Col>Expirations</Col>
              <Button size="sm" color="primary" onClick={() => setToggleModal(!toggleModal)}>
                See All
              </Button>
            </Row>
          </CardText>
        </CardBody>
      </Card>

      <ModalContent
        class="p-0"
        modal={toggleModal}
        toggle={() => setToggleModal(!toggleModal)}
        title="Expirations List"
        size="lg"
      >
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0" style={{ maxHeight: '62px' }}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === '1' ? 'active' : ''}`}
                  onClick={() => setActiveTab('1')}
                  style={customStyles.navLink}
                >
                  Children
                </NavLink>
              </NavItem>
              {isAdmin(user) && (
                <NavItem>
                  <NavLink
                    className={`${activeTab === '2' ? 'active' : ''}`}
                    onClick={() => setActiveTab('2')}
                    style={customStyles.navLink}
                  >
                    Staff
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </CardHeader>
          <CardBody>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="d-flex justify-content-between mb-3 mt-2 px-4">
                  <label className="form-control-label">Sort by:</label>
                  <span>
                    <Badge className="cursor-pointer mr-4" color="info" onClick={() => setKidsSortBy('UPCOMING')}>
                      Upcoming
                    </Badge>
                    <Badge className="cursor-pointer mr-4" color="warning" onClick={() => setKidsSortBy('EXPIRED')}>
                      Expired
                    </Badge>
                    <Badge className="cursor-pointer mr-4" color="danger" onClick={() => setKidsSortBy('PENDING')}>
                      Pending
                    </Badge>
                  </span>
                </div>
                <DataTable
                  header={false}
                  shadowStyle={false}
                  columns={columnsKids}
                  data={expirationKids}
                  totalRows={pagedMeta.total}
                  loading={loading}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </TabPane>
              <TabPane tabId="2">
                <div className="d-flex justify-content-between mb-3 mt-2 px-4">
                  <label className="form-control-label">Sort by:</label>
                  <span>
                    <Badge className="cursor-pointer mr-4" color="info" onClick={() => setUsersSortBy('UPCOMING')}>
                      Upcoming
                    </Badge>
                    <Badge className="cursor-pointer mr-4" color="warning" onClick={() => setUsersSortBy('EXPIRED')}>
                      Expired
                    </Badge>
                  </span>
                </div>
                <DataTable
                  header={false}
                  shadowStyle={false}
                  columns={columnsUsers}
                  data={expirationUsers}
                  totalRows={pagedMeta.total}
                  loading={loading}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </ModalContent>
    </>
  ) : (
    <></>
  );
};

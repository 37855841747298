import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Media,
} from 'reactstrap';
import { useContext, useEffect, useState } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import ProfileTab from './TabContent/ProfileTab';
import MedicalTab from './TabContent/MedicalTab';
import AsideProfile from './ProfileRightBar';
import TimelineTab from './TabContent/TimelineTab';
import FileTab from './TabContent/FileTab';
import IncidentReportTab from './TabContent/IncidentReportTab';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import KidsService from '../../../services/Kids';
import { isMobile } from 'react-device-detect';
import { ucfirst } from '../../../helpers/string';
import StudentDailyQuestionariesList from './TabContent/StudentDailyQuestionariesList';
import MessagingListTalk from '../Messaging/MessagingListTalk';

const StudentProfile = () => {
  const { token }: any = useContext(AuthContext);

  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      paddingLeft: isMobile ? 8 : undefined,
      paddingRight: isMobile ? 8 : undefined,
    },
  };
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const { id: kidId } = useParams<{ id: string }>();
  const currentTab = urlParams.get('tab');
  const [activeTab, setActiveTab] = useState('profile');
  const [kidPicture, setkidPicture] = useState('');
  const [kidName, setKidName] = useState('');

  const sudentTabs = {
    profile: { content: <ProfileTab />, name: 'Profile', icon: 'fas fa fa-user' },
    timeline: { content: <TimelineTab />, icon: 'fas fa fa-clock' },
    medical: { content: <MedicalTab />, icon: 'fas fa-notes-medical' },
    'incident-reports': {
      content: <IncidentReportTab />,
      name: `Incident${!isMobile ? ' Reports' : ''}`,
      icon: 'fas fa-notes-medical',
    },
    file: { content: <FileTab />, icon: 'fas fa-file' },
    'daily-questionary': {
      content: <StudentDailyQuestionariesList studentId={kidId}></StudentDailyQuestionariesList>,
      name: 'Daily Report',
      icon: 'fas fa-receipt',
    },
    messages: {
      content: <MessagingListTalk studentId={kidId} />,
      name: 'Messages',
      icon: 'fas fa-comment',
    },
  };

  const getById = async () => {
    try {
      const result = await KidsService(token).show(kidId);
      if (result?.data) {
        setkidPicture(result?.data.picture);
        setKidName(result?.data.firstName + ' ' + result?.data.lastName);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  const getTabName = (tabKey: string) => {
    return (sudentTabs as any)[tabKey]?.name || ucfirst(tabKey);
  };

  useEffect(() => {
    setActiveTab(urlParams.get('tab') || 'profile');
  }, [currentTab]);

  useEffect(() => {
    getById();
  }, [kidId]);

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0 mb-0 pb-0">
                <div style={{ marginBottom: '12px' }}>
                  <Media className="align-items-center">
                    <span className="avatar rounded-circle" style={{ width: '60px', height: '60px' }}>
                      <img
                        alt={`${kidName}`}
                        src={kidPicture ? `${kidPicture}` : require('../../../assets/img/user-default.png').default}
                        style={{ height: '100%' }}
                      />
                    </span>
                    <Media className="ml-2">
                      <span className="mb-0 text-sm font-weight-bold">{`${kidName}`}</span>
                    </Media>
                  </Media>
                </div>
                <div className={isMobile ? 'd-flex justify-content-center' : ''}>
                  <Nav className="border-0 nav-profile" tabs>
                    {Object.keys(sudentTabs).map((tab: string, i: number) => (
                      <NavItem className="cursor-pointer" key={`nav-item_${i}`}>
                        <NavLink
                          className="border-0 cursor-pointer font-weight-bold nav-link-profile"
                          style={customStyles.navLink}
                          active={activeTab === tab}
                          onClick={() => setActiveTab(tab)}
                        >
                          <i className={`${(sudentTabs as any)[tab]?.icon} ${!isMobile ? 'mr-2' : ''}`} />
                          <span>{getTabName(tab)}</span>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </div>
              </CardHeader>
              <CardBody
                className="d-flex flex-wrap w-100"
                style={isMobile && activeTab === 'profile' ? { paddingLeft: 5, paddingRight: 5 } : {}}
              >
                <Col xl="9" lg="8" md="12" xs="12" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
                  <TabContent activeTab={activeTab}>
                    {Object.keys(sudentTabs).map((tab: string, i: number) => (
                      <TabPane key={`tab-pane_${i}`} tabId={tab}>
                        {activeTab === tab && (sudentTabs as any)[tab].content}
                      </TabPane>
                    ))}
                  </TabContent>
                </Col>
                {!isMobile || (isMobile && activeTab === 'profile') ? (
                  <Col xl="3" lg="4" md="12" xs="12">
                    <AsideProfile />
                  </Col>
                ) : (
                  <></>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StudentProfile;

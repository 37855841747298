import DataTableComponent from 'react-data-table-component';
import { Card, CardHeader } from 'reactstrap';
import DataTableProgress from './DataTableProgress';
import { Button, FormGroup, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import history from '../../history';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';

type DataTableProps = {
  title?: string;
  subTitle?: string;
  columns: any[];
  data: any[];
  totalRows: number;
  loading?: boolean;
  button?: boolean;
  buttonUrl?: string;
  settingsButton?: boolean;
  filtersList?: any[];
  search?: boolean;
  isFilterButtons?: boolean;
  responsive?: boolean;
  onChangeRowsPerPage?: (perPage: number) => Promise<void>;
  onChangePage?: (page: number) => Promise<void>;
  onChangeFilter?: (filter: string) => void;
  onChangeSort?: (column: string, direction: string) => void;
  onClickSettings?: () => void;
  onSearch?: (search: string) => void;
  shadowStyle?: boolean;
  header?: boolean;
  conditionalRowStyles?: any[];
  pagination?: boolean;
};

const DataTable = ({
  title,
  subTitle,
  columns,
  data,
  totalRows,
  loading,
  button,
  buttonUrl,
  settingsButton,
  filtersList,
  search,
  isFilterButtons = false,
  responsive = true,
  onChangeRowsPerPage,
  onChangePage,
  onChangeFilter,
  onChangeSort,
  onClickSettings,
  onSearch,
  shadowStyle,
  header = true,
  conditionalRowStyles,
  pagination = true,
}: DataTableProps) => {
  const isTablet = window.innerWidth <= 1024 && !isMobile;
  const customStyles: any = {
    table: {
      style: {},
    },
    header: {
      style: {
        borderRadius: 'calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0',
        minHeight: '65px',
        fontSize: '1.0625rem',
        fontWeight: '900',
        lineHeight: '1.5',
      },
    },
    headCells: {
      style: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        background: '#F6F9FC',
        color: '#8898aa',
        fontSize: '0.65rem',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        verticalAlign: 'middle',
        fontWeight: '600',
      },
    },
    rows: {
      style: {
        minHeight: '65px',
        fontSize: '0.8125rem',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        color: '#525f7f',
      },
    },
    cells: {
      style: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        fontSize: '0.8125rem',
      },
    },
  };

  const [filterBy, setFilterBy] = useState<string>('');
  const changeFilter = (filter: string) => {
    if (onChangeFilter) {
      onChangeFilter(filter);
    }
  };

  const changeSearch = (text: string) => {
    if (onSearch) {
      onSearch(text);
    }
  };

  const handleSort = async (column: any, sortDirection: string) => {
    if (onChangeSort && column?.sortField) {
      onChangeSort(column?.sortField, sortDirection);
    }
  };

  columns = columns.map((col) => {
    if (col.route) {
      return Object.assign(
        {
          name: '',
          selector: (row: any) => {
            const label = <>{col.label ? col.label(row) : row.id}</>;

            return col.disabled ? (
              label
            ) : (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(typeof col.route === 'string' ? `${col.route}/${row.id}` : col.route(row));
                }}
              >
                <h3 style={{ fontSize: 15 }} className="text-primary">
                  {label}
                </h3>
              </a>
            );
          },
          grow: 1,
        },
        col,
      );
    } else if (col.type === 'delete') {
      return Object.assign(
        {
          name: '',
          selector: (row: any) => (
            <i
              className="far fa-trash-alt text-danger"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                col.onClick(row);
              }}
            ></i>
          ),
          grow: 1,
        },
        col,
      );
    }

    return col;
  });
  const visibleHeader = header || (!header && title);

  return (
    <>
      <Card className={`${shadowStyle ? 'shadow' : 'border-0'}`}>
        {visibleHeader && (
          <CardHeader className="border-0">
            <div className="row">
              <div className="col-12">
                <h3 className="mb-0">
                  {title}
                  {subTitle ? <small>{` (${subTitle})`}</small> : <></>}
                </h3>
              </div>
              <div className="col-12 mt-3">
                <div className="row">
                  {search ? (
                    <div className={isMobile || isTablet ? 'col-12' : 'col-6'}>
                      <FormGroup>
                        <Input type="text" placeholder="Search" onChange={(e) => changeSearch(e.target.value)}></Input>
                      </FormGroup>
                    </div>
                  ) : (
                    <></>
                  )}
                  {!isFilterButtons && filtersList && (
                    <div className={`${isMobile || isTablet ? 'col-12' : 'col-6'} ${search ? '' : 'mt-3'}`}>
                      <FormGroup style={{ maxWidth: 150 }}>
                        <Input type="select" onChange={(e) => changeFilter(e.target.value)}>
                          {filtersList?.map((filter: any, index: number) => {
                            return (
                              <option
                                value={filter.value}
                                key={`filter-button-${index}`}
                                onChange={() => {
                                  changeFilter(filter.value);
                                }}
                              >
                                {filter.label}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </div>
                  )}
                  {isFilterButtons && (
                    <>
                      <div className="col-6">
                        <div className="btn-group" role="group" aria-label="Filters">
                          {isFilterButtons &&
                            filtersList?.map((filter: any, index: number) => {
                              if (filterBy === filter.value) {
                                return (
                                  <button
                                    className="btn btn-primary"
                                    key={`filter-button-${index}`}
                                    onClick={() => {
                                      changeFilter(filter.value);
                                      setFilterBy(filter.value);
                                    }}
                                  >
                                    {filter.label}
                                  </button>
                                );
                              }
                              return (
                                <button
                                  className={`btn btn-${filter.isActive ? 'primary' : 'secondary'}`}
                                  key={`filter-button-${index}`}
                                  onClick={() => {
                                    changeFilter(filter.value);
                                    setFilterBy(filter.value);
                                  }}
                                >
                                  {filter.label}
                                </button>
                              );
                            })}
                        </div>
                      </div>
                    </>
                  )}

                  {!search && !filtersList ? <div className="col-6 mt-3"></div> : <></>}
                  <div style={{ position: 'absolute', right: 0 }}>
                    <div className="col float-right">
                      {button && (
                        <Link to={{ pathname: buttonUrl }} className="float-right">
                          <Button color="primary">Create New</Button>
                        </Link>
                      )}
                      {settingsButton && (
                        <i
                          className="fas fa-cog fa-lg float-right mt-3 mr-4"
                          style={{ cursor: 'pointer' }}
                          onClick={onClickSettings}
                        ></i>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardHeader>
        )}
        <DataTableComponent
          responsive={responsive}
          customStyles={customStyles}
          columns={columns}
          data={data}
          progressPending={loading}
          progressComponent={<DataTableProgress />}
          pagination={pagination}
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          onSort={handleSort}
          conditionalRowStyles={conditionalRowStyles}
        />
      </Card>
    </>
  );
};

export default DataTable;

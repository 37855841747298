/* eslint-disable max-lines */
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroup,
  Label,
  InputGroupText,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import SchoolService from '../../../services/School';
import history from '../../../history';
import SchoolAddress from './SchoolAddress';
import FranchiseeService from '../../../services/Franchisee';
import confirmAlert from '../../../helpers/confirmAlert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TimePicker from '../../../components/Core/TimePicker';
import InputMask from 'react-input-mask';
import moment from 'moment';
import axios from 'axios';
import { generate } from '../../../helpers/uuid';
import NumberFormat from 'react-number-format';
import { timezones } from '../../../helpers/timezones';
import ReactDatetime from 'react-datetime';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';

const SchoolCreate = ({ withCommonHeader = true }) => {
  const { id } = useParams<{ id: string }>();
  const pageTitle = id ? 'Update' : 'Create';
  const { user, token, setLastSchool, currentFranchisee }: any = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [operationSchool, setOperationSchool] = useState(false);
  const [expectedStartDate, setExpectedStartDate] = useState('');
  const [savedSchoolId, setSavedSchoolId] = useState(id || '');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [federalTaxId, setFederalTaxId] = useState('');
  const [stateTaxId, setStateTaxId] = useState('');
  const [franchiseeId, setFranchiseeId] = useState('');
  const [uri, setUri] = useState('');
  const [waitingList, setWaitingList] = useState(false);
  const [tourList, setTourList] = useState(false);
  const [tokenKiosk, setTokenKiosk] = useState('');
  const [registrationFee, setRegistrationFee] = useState(0);
  const [supplyFee, setSupplyFee] = useState(0);

  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [youTube, setYouTube] = useState('');
  const [paymentFrequency, setPaymentFrequency] = useState('');
  const [paperwork, setPaperwork] = useState('');

  const [stripLikeKeyPk, setStripLikeKeyPk] = useState('');
  const [stripLiveSecretSk, setStripLiveSecretSk] = useState('');
  const [stripTestKeyPk, setStripTestKeyPk] = useState('');
  const [stripTestSecretSk, setStripTestSecretSk] = useState('');
  const [stripLikeKeyPkEdit, setStripLikeKeyPkEdit] = useState(true);
  const [stripLiveSecretSkEdit, setStripLiveSecretSkEdit] = useState(true);
  const [stripTestKeyPkEdit, setStripTestKeyPkEdit] = useState(true);
  const [stripTestSecretSkEdit, setStripTestSecretSkEdit] = useState(true);
  const [stripeProduction, setStripeProduction] = useState(false);

  const [timeOperationStart, setTimeOperationStart] = useState(undefined as undefined | string);
  const [timeOperationEnd, setTimeOperationEnd] = useState(undefined as undefined | string);

  const [submitAddress, setSubmitAddress] = useState(false);

  const [selectList, setSelectList] = useState([{}]);

  const [ip, setIP] = useState('');
  const [priceSheetPdf, setPriceSheetPdf] = useState<any>();
  const [priceSheetPdfUploded, setPriceSheetPdfUploded] = useState<any>('');
  const [enrollSecret, setEnrollSecret] = useState('');
  const [onlineEnrollment, setOnlineEnrollment] = useState(false);
  const [timezone, setTimezone] = useState('America/New_York');

  const [studentAnnualVacationTime, setStudentAnnualVacationTime] = useState(1);

  const imageRef = useRef(null as any);

  const validPaymentFrequency = [
    { value: 'WEEKLY', label: 'Weekly' },
    { value: 'MONTHLY', label: 'Monthly' },
  ];

  const onChangeFile = (event: any) => {
    setPriceSheetPdfUploded(null);
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setPriceSheetPdf(file);
  };

  const showOpenFileDialog = () => {
    if (isDisabled) {
      return;
    }
    if (imageRef?.current?.click) {
      imageRef.current.click();
    }
  };

  const handleGetIP = async () => {
    const res = await axios.get('https://geolocation-db.com/json/');
    setIP(res.data.IPv4);
  };

  const generateUri = (value: string) => {
    const uriName = value.replaceAll(' ', '-').toLowerCase();
    setUri(uriName);
  };

  useEffect(() => {
    generateUri(name);
  }, [name]);

  const listSelectFranchisee = async () => {
    try {
      const result = await FranchiseeService(token).listSelect();
      setSelectList(result.data || []);
      setFranchiseeId(currentFranchisee.value);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No franchises found');
    }
  };

  const changeTokenSchool = async (e: React.FormEvent) => {
    e.preventDefault();
    return confirmAlert({
      title: 'Change Token',
      messageType: 'EDIT',
      message: 'Are you sure you want to update the school token? All Kiosks will be disconnected',
      onClickYes: handleTokenSchool,
    });
  };

  const handleTokenSchool = async () => {
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    try {
      await SchoolService(token).changeTokenSchool({ id });
      await getById();
      toast.success('Updated successfully!');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Update error!');
      toast.error(message);
    } finally {
      setIsDisabled(false);
    }

    setLoading(false);
  };

  const dateToday = moment().format('YYYY-MM-DD');

  const getById = async (idSchool?: string) => {
    try {
      const result = await SchoolService(token).show(idSchool || id);
      if (result?.data) {
        setName(result?.data?.name || '');
        setFederalTaxId(result?.data?.federalTaxId || '');
        setFranchiseeId(result?.data?.franchiseeId || '');
        setUri(result?.data?.uri || '');
        setWaitingList(result?.data?.waitingList || false);

        setTimeOperationStart(
          result?.data?.timeOperationStart ? `${dateToday} ${result?.data?.timeOperationStart}` : undefined,
        );
        setTimeOperationEnd(
          result?.data?.timeOperationEnd ? `${dateToday} ${result?.data?.timeOperationEnd}` : undefined,
        );
        setTokenKiosk(result?.data?.tokenKiosk || '');
        setStripLikeKeyPk(
          result?.data?.stripLikeKeyPk ? `************************${result?.data?.stripLikeKeyPk.slice(-5)}` : '',
        );
        setStripLiveSecretSk(
          result?.data?.stripLiveSecretSk ? `************************${result?.data?.stripLiveSecretSk.slice(-5)}` : '',
        );
        setStripTestKeyPk(
          result?.data?.stripTestKeyPk ? `************************${result?.data?.stripTestKeyPk.slice(-5)}` : '',
        );
        setStripTestSecretSk(
          result?.data?.stripTestSecretSk ? `************************${result?.data?.stripTestSecretSk.slice(-5)}` : '',
        );
        setStripeProduction(String(result?.data?.stripeMode) === 'PRODUCTION');

        setInstagram(result?.data?.instagram || '');
        setFacebook(result?.data?.facebook || '');
        setYouTube(result?.data?.youTube || '');
        setPaymentFrequency(result?.data?.paymentFrequency || '');
        setPaperwork(result?.data?.paperwork || '');

        setIP(result?.data?.ip || '');
        setPriceSheetPdfUploded(result?.data?.priceSheetPdf || '');
        setEnrollSecret(result?.data?.enrollSecret || '');
        setOnlineEnrollment(result?.data?.onlineEnrollment || false);
        setPhone(result?.data?.phone || '');
        setStateTaxId(result?.data?.stateTaxId || '');
        setOperationSchool(result?.data?.operationSchool || false);
        setExpectedStartDate(
          result?.data?.expectedStartDate ? toLocaleFormatSlash(result?.data?.expectedStartDate) : '',
        );
        setTourList(result?.data?.tourList || false);
        setRegistrationFee(parseFloat(result?.data?.registrationFee) || 0);
        setSupplyFee(parseFloat(result?.data?.supplyFee) || 0);
        setStudentAnnualVacationTime(result?.data?.studentAnnualVacationTime || 1);

        setSubmitAddress(false);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid school ID');
    }
  };

  useEffect(() => {
    if (id) {
      getById();
    }
    if (user?.franchisee) {
      setSelectList([{ id: user?.franchisee?.id, name: user?.franchisee?.name }]);
      setFranchiseeId(user?.franchisee?.id);
      return;
    }
    listSelectFranchisee();
  }, []);

  useEffect(() => {
    setFranchiseeId(currentFranchisee.value);
  }, [currentFranchisee]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const timeOperationStartMoment = moment(timeOperationStart);
    const timeOperationEndMoment = moment(timeOperationEnd);
    const expectedStartDateMoment = moment(expectedStartDate);

    const body = {
      operationSchool,
      name,
      phone,
      ip,
      federalTaxId,
      stateTaxId,
      franchiseeId,
      uri,
      waitingList,
      tourList,
      timeOperationStart: timeOperationStartMoment.format('HH:mm:ss'),
      timeOperationEnd: timeOperationEndMoment.format('HH:mm:ss'),
      registrationFee: registrationFee ? registrationFee : 0,
      supplyFee: supplyFee ? supplyFee : 0,
      stripLikeKeyPk: stripLikeKeyPk.replace('************************', ''),
      stripLiveSecretSk: stripLiveSecretSk.replace('************************', ''),
      stripTestKeyPk: stripTestKeyPk.replace('************************', ''),
      stripTestSecretSk: stripTestSecretSk.replace('************************', ''),
      stripeMode: stripeProduction ? 'PRODUCTION' : 'TEST',
      enrollSecret,
      onlineEnrollment,
      expectedStartDate: expectedStartDateMoment.format('YYYY-MM-DD'),
      instagram,
      facebook,
      youTube,
      studentAnnualVacationTime,
      paymentFrequency,
      paperwork,
      timezone,
    };

    try {
      const result = id ? await SchoolService(token).update(id, body) : await SchoolService(token).create(body);

      if (priceSheetPdf) {
        const formData = new FormData();
        formData.append('file', priceSheetPdf as any);
        await SchoolService(token).file(result?.data?.id, formData);
      }

      toast.success(`${id ? 'Updated' : 'Created'} successfully!`);
      if (result?.data?.id) {
        setLastSchool(result?.data?.id);
        setSavedSchoolId(result?.data?.id);
        setSubmitAddress(true);
        history.push(`/school/${result?.data?.id}`);
      }
      await getById(result?.data?.id);
      setStripLikeKeyPkEdit(true);
      setStripLiveSecretSkEdit(true);
      setStripTestKeyPkEdit(true);
      setStripTestSecretSkEdit(true);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    if (id) {
      setSubmitAddress(false);
    }

    setLoading(false);
    setIsDisabled(false);
  };

  const handleUploadPdf = () => {
    window.open(priceSheetPdfUploded, '_blank');
  };

  const getTimezones = () => {
    const _timezones: {} = {};
    timezones.map((timezone: any) => {
      return timezone.utc.map((utc: string) => {
        (_timezones as any)[utc] = {
          text: `${utc} (UTC${
            timezone.offset === 0 ? '' : `${timezone.offset > 0 ? '+' : ''}${timezone.offset.toString() + ':00'}`
          })`,
          offset: timezone.offset,
          timezone: utc,
        };
      });
    });
    return Object.values(_timezones)
      .sort((a: any, b: any) => a.text.localeCompare(b.text))
      .map((timezone: any, i: number) => {
        return (
          <option value={timezone.timezone} key={`filter-timezone-${i}`}>
            {timezone.text}
          </option>
        );
      });
  };

  return (
    <>
      {withCommonHeader && <CommonHeader />}
      <Container className={withCommonHeader ? 'mt--8' : ''} fluid={withCommonHeader}>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              {withCommonHeader && (
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{`${pageTitle} School`}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Link to="/school">
                        <Button color="primary" className="float-right">
                          Back
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
              )}
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="2">
                        <FormGroup>
                          <Row>
                            <Col>
                              <label className="form-control-label" htmlFor="input-waitingList">
                                Operating school?
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  onChange={(e) => setOperationSchool(e.target.checked)}
                                  checked={operationSchool}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-name">
                            Expected Start Date
                          </label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                              inputProps={{
                                placeholder: 'Expected Start Date',
                                required: true,
                              }}
                              onChange={(newValue) => {
                                setExpectedStartDate(`${newValue}`);
                              }}
                              timeFormat={false}
                              value={`${expectedStartDate}`}
                              dateFormat={'MM/DD/yyyy'}
                              closeOnSelect={true}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-franchisee-id">
                            Franchisee
                          </label>
                          <Input type="select" onChange={(e) => setFranchiseeId(e.target.value)} value={franchiseeId}>
                            {selectList?.map((filter: any, index: number) => {
                              return (
                                <option value={filter.id} key={`filter-button-${index}`}>
                                  {filter.name} - {filter.id}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-name">
                            Center Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-name"
                            placeholder="School Name"
                            type="text"
                            disabled={isDisabled}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-phone">
                            Center Phone
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-phone"
                            placeholder="School Phone"
                            mask="(999) 999-9999"
                            tag={InputMask}
                            type="text"
                            disabled={isDisabled}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Token Kiosk</label>
                          <InputGroup>
                            <Input
                              className="form-control-alternative"
                              placeholder="Token Kiosk"
                              type="text"
                              value={tokenKiosk}
                              disabled={true}
                              onChange={(e) => setTokenKiosk(e.target.value)}
                            />
                            <InputGroupAddon addonType="append">
                              <Button color="success" onClick={changeTokenSchool}>
                                Change
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label"> Time Operation Start</label>
                          <TimePicker
                            minuteStep={5}
                            defaultValue={timeOperationStart}
                            onChangeTime={(timeOperationStart: string) => {
                              setTimeOperationStart(timeOperationStart);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label"> Time Operation End</label>
                          <TimePicker
                            minuteStep={5}
                            defaultValue={timeOperationEnd}
                            onChangeTime={(timeOperationEnd: string) => {
                              setTimeOperationEnd(timeOperationEnd);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Center Ip</label>
                          <InputGroup>
                            <Input
                              className="form-control-alternative"
                              placeholder="My Ip"
                              type="text"
                              disabled={true}
                              value={ip}
                            />
                            <InputGroupAddon addonType="append">
                              <Button color="success" onClick={handleGetIP}>
                                Get my IP
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-uri">
                            Center Uri
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-uri"
                            placeholder="Uri"
                            type="text"
                            value={uri}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-federal-tax-id">
                            Federal Tax ID
                          </label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Federal Tax ID"
                            type="text"
                            id="input-federal-tax-id"
                            disabled={isDisabled}
                            value={federalTaxId}
                            onChange={(e) => setFederalTaxId(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-state-tax-id">
                            State Tax ID
                          </label>
                          <Input
                            className="form-control-alternative"
                            placeholder="State Tax ID"
                            id="input-state-tax-id"
                            type="text"
                            disabled={isDisabled}
                            value={stateTaxId}
                            onChange={(e) => setStateTaxId(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-country">
                            Timezone
                          </label>
                          <Input
                            type="select"
                            onChange={(e) => setTimezone(e.target.value)}
                            defaultValue={'America/New_York'}
                          >
                            {getTimezones()}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <SchoolAddress schoolId={savedSchoolId} onSubmitForm={submitAddress} />
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Social Network</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">Instagram</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Instagram"
                            type="text"
                            disabled={isDisabled}
                            value={instagram}
                            onChange={(e) => setInstagram(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">Facebook</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Facebook"
                            type="text"
                            disabled={isDisabled}
                            value={facebook}
                            onChange={(e) => setFacebook(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">YouTube</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="YouTube"
                            type="text"
                            disabled={isDisabled}
                            value={youTube}
                            onChange={(e) => setYouTube(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">Enrollment Paperwork</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Enrollment Paperwork"
                            type="text"
                            disabled={isDisabled}
                            value={paperwork}
                            onChange={(event) => setPaperwork(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Billing</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-registration-fee">
                            Registration Fee
                          </label>
                          <Input
                            type="text"
                            thousandsGroupStyle="thousand"
                            prefix="$"
                            decimalSeparator="."
                            displayType="input"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            tag={NumberFormat}
                            value={registrationFee || 0}
                            onValueChange={({ value }: any) => setRegistrationFee(Number(value))}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-supply-fee">
                            Supply Fee
                          </label>
                          <Input
                            type="text"
                            thousandsGroupStyle="thousand"
                            prefix="$"
                            decimalSeparator="."
                            displayType="input"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            tag={NumberFormat}
                            value={supplyFee || 0}
                            onValueChange={({ value }: any) => setSupplyFee(Number(value))}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label">Payment Frequency</label>
                          <Input
                            type="select"
                            onChange={(e) => setPaymentFrequency(e.target.value)}
                            value={paymentFrequency}
                            disabled={isDisabled}
                          >
                            {validPaymentFrequency.map((a: any, index: number) => {
                              return (
                                <option value={a.value} key={`payment-frequency-option-${index}`}>
                                  {a.label}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      {id ? (
                        <>
                          <input
                            name="picture"
                            type="file"
                            accept=".pdf"
                            style={{ display: 'none' }}
                            ref={imageRef}
                            disabled={isDisabled}
                            onChange={onChangeFile}
                          />
                          {!priceSheetPdfUploded ? (
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="token-kiosk">
                                  Price Sheet PDF
                                </label>

                                <InputGroup>
                                  <Input
                                    className="form-control-alternative"
                                    placeholder="No file selected"
                                    type="text"
                                    value={priceSheetPdf?.name}
                                    disabled={true}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" onClick={showOpenFileDialog}>
                                      Upload
                                    </Button>
                                  </InputGroupAddon>
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          ) : (
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="token-kiosk">
                                  Price Sheet PDF Uploaded
                                </label>
                                <InputGroup>
                                  <button type="button" className="btn text-primary" onClick={handleUploadPdf}>
                                    Download File
                                  </button>
                                  <InputGroupAddon addonType="append">
                                    <Button color="success" onClick={() => showOpenFileDialog()}>
                                      Change
                                    </Button>
                                  </InputGroupAddon>
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          )}
                        </>
                      ) : (
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="token-kiosk">
                              Price Sheet PDF Uploaded
                            </label>
                            <Input
                              className="form-control-alternative text-danger"
                              value="Save the initial data to upload price sheet PDF"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">Stripe</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-stripe-test-key-pk">
                              Stripe Test Key PK
                            </label>
                            <InputGroup>
                              <Input
                                className="form-control-alternative"
                                id="tour"
                                type="text"
                                value={stripTestKeyPk}
                                onChange={(e) => setStripTestKeyPk(e.target.value)}
                                disabled={stripTestKeyPkEdit}
                              />
                              <InputGroupAddon addonType="append">
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    if (stripTestKeyPkEdit) {
                                      setStripTestKeyPk('');
                                    }
                                    setStripTestKeyPkEdit(!stripTestKeyPkEdit);
                                  }}
                                >
                                  Edit
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-stripe-test-secret-sk">
                              Stripe Test Secret SK
                            </label>
                            <InputGroup>
                              <Input
                                className="form-control-alternative"
                                id="tour"
                                type="text"
                                value={stripTestSecretSk}
                                onChange={(e) => setStripTestSecretSk(e.target.value)}
                                disabled={stripTestSecretSkEdit}
                              />
                              <InputGroupAddon addonType="append">
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    if (stripTestSecretSkEdit) {
                                      setStripTestSecretSk('');
                                    }
                                    setStripTestSecretSkEdit(!stripTestSecretSkEdit);
                                  }}
                                >
                                  Edit
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-stripe-like-key-pk">
                              Stripe Live Key PK
                            </label>
                            <InputGroup>
                              <Input
                                className="form-control-alternative"
                                id="tour"
                                type="text"
                                value={stripLikeKeyPk}
                                onChange={(e) => setStripLikeKeyPk(e.target.value)}
                                disabled={stripLikeKeyPkEdit}
                              />
                              <InputGroupAddon addonType="append">
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    if (stripLikeKeyPkEdit) {
                                      setStripLikeKeyPk('');
                                    }
                                    setStripLikeKeyPkEdit(!stripLikeKeyPkEdit);
                                  }}
                                >
                                  Edit
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-stripe-live-secret-sk">
                              Stripe Live Secret SK
                            </label>

                            <InputGroup>
                              <Input
                                className="form-control-alternative"
                                id="tour"
                                type="text"
                                value={stripLiveSecretSk}
                                onChange={(e) => setStripLiveSecretSk(e.target.value)}
                                disabled={stripLiveSecretSkEdit}
                              />
                              <InputGroupAddon addonType="append">
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    if (stripLiveSecretSkEdit) {
                                      setStripLiveSecretSk('');
                                    }
                                    setStripLiveSecretSkEdit(!stripLiveSecretSkEdit);
                                  }}
                                >
                                  Edit
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup check>
                            <Input
                              name="mode"
                              type="radio"
                              onChange={(e) => setStripeProduction(e.target.checked ? false : true)}
                              checked={!stripeProduction}
                            />{' '}
                            <Label check>Test Mode</Label>
                          </FormGroup>
                          <FormGroup check>
                            <Input
                              name="mode"
                              type="radio"
                              onChange={(e) => setStripeProduction(e.target.checked ? true : false)}
                              checked={stripeProduction}
                            />{' '}
                            <Label check>Production Mode</Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Vacation Time</h6>
                  <div className="pl-lg-4"></div>
                  <Row>
                    <Col lg="3">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-stripe-like-key-pk">
                          Student Annual Vacation Time
                        </label>
                        <Input
                          type="select"
                          onChange={(e) => setStudentAnnualVacationTime(Number(e.target.value))}
                          value={studentAnnualVacationTime}
                        >
                          {[1, 2, 3, 4].map((filter: any, index: number) => {
                            return (
                              <option value={filter} key={`filter-button-${index}`}>
                                {`${filter} Week${filter > 1 ? 's' : ''}`}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  {id ? (
                    <>
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">Tour</h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="token-kiosk">
                                Tour url
                              </label>
                              <InputGroup>
                                <Input
                                  className="form-control-alternative"
                                  id="tour"
                                  type="text"
                                  value={`${process.env.REACT_APP_TOOLS_URL || ''}/tour/${franchiseeId}/${uri}`}
                                  disabled={true}
                                />
                                <InputGroupAddon addonType="append">
                                  <CopyToClipboard
                                    text={`${process.env.REACT_APP_TOOLS_URL || ''}/tour/${franchiseeId}/${uri}`}
                                  >
                                    <Button color="success">Copy</Button>
                                  </CopyToClipboard>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col lg="2">
                            <FormGroup>
                              <Row>
                                <Col>
                                  <label className="form-control-label" htmlFor="input-waitingList">
                                    Enable tour
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <label className="custom-toggle">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) => setTourList(e.target.checked)}
                                      checked={tourList}
                                    />
                                    <span className="custom-toggle-slider rounded-circle" />
                                  </label>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">Waiting List</h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="token-kiosk">
                                Waiting List url
                              </label>
                              <InputGroup>
                                <Input
                                  className="form-control-alternative"
                                  id="tour"
                                  type="text"
                                  value={`${process.env.REACT_APP_TOOLS_URL || ''}/waiting-list/${uri}`}
                                  disabled={true}
                                />
                                <InputGroupAddon addonType="append">
                                  <CopyToClipboard
                                    text={`${process.env.REACT_APP_TOOLS_URL || ''}/waiting-list/${uri}`}
                                  >
                                    <Button color="success">Copy</Button>
                                  </CopyToClipboard>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col lg="2">
                            <FormGroup>
                              <Row>
                                <Col>
                                  <label className="form-control-label" htmlFor="input-waitingList">
                                    Enable Waiting List
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <label className="custom-toggle">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) => setWaitingList(e.target.checked)}
                                      checked={waitingList}
                                    />
                                    <span className="custom-toggle-slider rounded-circle" />
                                  </label>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">Enroll</h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="token-kiosk">
                                Enroll url
                              </label>
                              <InputGroup>
                                <Input
                                  className="form-control-alternative"
                                  id="tour"
                                  type="text"
                                  value={`${process.env.REACT_APP_TOOLS_URL || ''}/enroll/${uri}${
                                    enrollSecret ? `?code=${enrollSecret}` : ''
                                  }`}
                                  disabled={true}
                                />
                                <InputGroupAddon addonType="append">
                                  <CopyToClipboard
                                    text={`${process.env.REACT_APP_TOOLS_URL || ''}/enroll/${uri}${
                                      enrollSecret ? `?code=${enrollSecret}` : ''
                                    }`}
                                  >
                                    <Button color="success">Copy</Button>
                                  </CopyToClipboard>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="token-kiosk">
                                Enroll Secret
                              </label>
                              <InputGroup>
                                <Input
                                  className="form-control-alternative"
                                  id="input-name"
                                  type="text"
                                  disabled={true}
                                  value={enrollSecret}
                                />
                                <InputGroupAddon addonType="append">
                                  <Button color="success" onClick={() => setEnrollSecret(generate())}>
                                    Generate
                                  </Button>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col lg="2">
                            <FormGroup>
                              <Row>
                                <Col>
                                  <label className="form-control-label" htmlFor="input-waitingList">
                                    Online Enrollment
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <label className="custom-toggle">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) => setOnlineEnrollment(e.target.checked)}
                                      checked={onlineEnrollment}
                                    />
                                    <span className="custom-toggle-slider rounded-circle" />
                                  </label>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="pl-lg-4">
                    <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SchoolCreate;

/* eslint-disable max-lines */
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  CardTitle,
} from 'reactstrap';
import React from 'react';
import AddToCalendar from 'react-add-to-calendar';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import history from '../../../history';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import ReactDatetime from 'react-datetime';
import { confirmAlert } from 'react-confirm-alert';
import TimePicker from '../../../components/Core/TimePicker';
import SchoolScheduleEvents from '../../../services/SchoolScheduleEvents';
import moment from 'moment';
import isAdmin from '../../../helpers/isAdmin';

type schoolScheduleProps = {
  isOpen: boolean;
  scheduleId: string;
  onModalStatus: () => void;
  dateClick: string;
  onCreateSchedule: () => void;
  formEventType: string;
  loggedUser?: any;
};

const SchoolScheduleEventForm = ({
  isOpen,
  scheduleId,
  onModalStatus,
  dateClick,
  onCreateSchedule,
  formEventType,
  loggedUser,
}: schoolScheduleProps) => {
  const { token, currentSchool, user }: any = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actualEvent, setActualEvent] = useState<{
    title?: string;
    startTime?: string;
    endTime?: string;
    location?: string;
    description?: string;
  }>({});

  const [schoolId, setSchoolId] = useState(currentSchool.value);

  const [allDay, setAllDay] = useState(true);
  const [closureSchool, setClosureSchool] = useState(false);
  const [name, setName] = useState<{
    name?: string;
    protein?: string;
    vegetable?: string;
    fruits?: string;
    drink?: string;
    am?: string;
    pm?: string;
  }>();
  const [date, setDate] = useState(dateClick);
  const [startAt, setStartAt] = useState(undefined as undefined | string);
  const [endAt, setEndAt] = useState(undefined as undefined | string);

  const changeNameIndex = (index: string, value: string) => {
    const oldName = name;
    setName({
      name: index === 'name' ? value : oldName?.name,
      protein: index === 'protein' ? value : oldName?.protein,
      vegetable: index === 'vegetable' ? value : oldName?.vegetable,
      fruits: index === 'fruits' ? value : oldName?.fruits,
      drink: index === 'drink' ? value : oldName?.drink,
      am: index === 'am' ? value : oldName?.am,
      pm: index === 'pm' ? value : oldName?.pm,
    });
  };

  useEffect(() => {
    setSchoolId(currentSchool.value);
  }, [isOpen, currentSchool]);

  const changeonModalStatus = () => {
    onModalStatus();
  };

  useEffect(() => {
    if (!loggedUser) {
      loggedUser = user;
    }
    setIsDisabled(!isAdmin(loggedUser));
    showEvent();
  }, []);

  const showEvent = async () => {
    try {
      setEndAt(moment().add(30, 'm').format('YYYY-MM-DD HH:mm:ss'));
      if (scheduleId) {
        const result = await SchoolScheduleEvents(token).show(scheduleId);
        if (result?.data) {
          setName(result?.data?.name || '');
          setDate(moment.utc(result?.data?.startAt).format('MM/DD/YYYY') || '');
          setStartAt(moment.utc(result?.data?.startAt).format('YYYY-MM-DD HH:mm:ss'));
          setEndAt(moment.utc(result?.data?.endAt).format('YYYY-MM-DD HH:mm:ss'));
          setAllDay(result?.data?.isAllDay);
          setClosureSchool(result?.data?.isClosureSchool);

          if (formEventType === 'calendar') {
            setActualEvent({
              title: result?.data?.name.name,
              startTime: moment.utc(result?.data?.startAt).format('YYYY-MM-DD HH:mm:ss'),
              endTime: moment.utc(result?.data?.endAt).format('YYYY-MM-DD HH:mm:ss'),
              location: '',
              description: '',
            });
          }
        }
      } else {
        onModalStatus();
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No schedule found');
    }
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);
    const body = {
      schoolId,
      type: formEventType,
      name,
      startAt: date + ' ' + (startAt ? moment(startAt).format('HH:mm:ss') : '00:00:00'),
      endAt: date + ' ' + (startAt ? moment(endAt).format('HH:mm:ss') : '00:00:00'),
      isAllDay: allDay,
      isClosureSchool: closureSchool,
    };

    try {
      const result = scheduleId
        ? await SchoolScheduleEvents(token).update(scheduleId, body)
        : await SchoolScheduleEvents(token).create(body);

      toast.success(`${scheduleId ? 'Updated' : 'Created'} successfully!`);
      if (result?.data?.id) {
        onModalStatus();
        onCreateSchedule();
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }
    setLoading(false);
    setIsDisabled(false);
  };

  const deleteItemAlert = async (id: string) => {
    changeonModalStatus();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Container fluid>
            <Card>
              <CardBody>
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  Delete
                </CardTitle>
                <Row className="mt-3">
                  <Col md="12 text-center">
                    <span className="h2 font-weight-bold mb-0">{`Are you sure to delete item?`}</span>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md="6 text-center">
                    <Button
                      color="primary"
                      href="#"
                      onClick={() => {
                        deleteItem(id);
                        onClose();
                      }}
                      size="lg"
                      style={{ minWidth: '100px' }}
                    >
                      Yes
                    </Button>
                  </Col>
                  <Col md="6 text-center">
                    <Button color="danger" href="#" onClick={onClose} size="lg" style={{ minWidth: '100px' }}>
                      No
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        );
      },
    });
  };

  const deleteItem = async (id: string) => {
    try {
      await SchoolScheduleEvents(token).delete(id);
      toast.success(`Deleted successfully!`);
      onCreateSchedule();
      history.push(`/monthly-info`);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || `Error deleting item: ${id}`);
      return toast.error(message);
    }
  };

  return (
    <>
      <Container fluid>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            <CardBody>
              {formEventType === 'snack' && (
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-eventName">
                        AM Snack
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-eventName"
                        placeholder="AM Snack"
                        type="text"
                        disabled={isDisabled}
                        value={name?.am}
                        onChange={(e) => changeNameIndex('am', e.target.value)}
                        required
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-eventName">
                        PM Snack
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-eventName"
                        placeholder="PM Snack"
                        type="text"
                        disabled={isDisabled}
                        value={name?.pm}
                        onChange={(e) => changeNameIndex('pm', e.target.value)}
                        required
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              {formEventType === 'lunch' && (
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-eventName">
                        Protein
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-eventName"
                        placeholder="Protein"
                        type="text"
                        disabled={isDisabled}
                        value={name?.protein}
                        onChange={(e) => changeNameIndex('protein', e.target.value)}
                        required
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-eventName">
                        Vegetable
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-eventName"
                        placeholder="Vegetable"
                        type="text"
                        disabled={isDisabled}
                        value={name?.vegetable}
                        onChange={(e) => changeNameIndex('vegetable', e.target.value)}
                        required
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-eventName">
                        Fruit
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-eventName"
                        placeholder="Fruit"
                        type="text"
                        disabled={isDisabled}
                        value={name?.fruits}
                        onChange={(e) => changeNameIndex('fruits', e.target.value)}
                        required
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-eventName">
                        Drink
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-eventName"
                        placeholder="Drink"
                        type="text"
                        disabled={isDisabled}
                        value={name?.drink}
                        onChange={(e) => changeNameIndex('drink', e.target.value)}
                        required
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              {formEventType === 'calendar' && (
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-eventName">
                        Event Name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-eventName"
                        placeholder="Event Name"
                        type="text"
                        disabled={isDisabled}
                        value={name?.name}
                        onChange={(e) => changeNameIndex('name', e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-eventDate">
                        Event Date
                      </label>
                      <InputGroup className={`input-group-alternative ${isDisabled ? 'disabled' : ''}`}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Event Date',
                            disabled: isDisabled,
                          }}
                          onChange={(newValue) => {
                            setDate(`${newValue}`);
                          }}
                          timeFormat={false}
                          dateFormat={'MM/DD/yyyy'}
                          closeOnSelect={true}
                          value={date ? toLocaleFormatSlash(date) : date}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  {!isDisabled && (
                    <>
                      <Col lg="6">
                        <FormGroup>
                          <Row>
                            <Col>
                              <label className="form-control-label">All Day?</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <Input type="checkbox" onChange={(e) => setAllDay(e.target.checked)} checked={allDay}>
                                  <option className="custom-toggle-slider rounded-circle" />
                                </Input>
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Row>
                            <Col>
                              <label className="form-control-label">School Closure?</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  onChange={(e) => setClosureSchool(e.target.checked)}
                                  checked={closureSchool}
                                >
                                  <option className="custom-toggle-slider rounded-circle" />
                                </Input>
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </>
                  )}

                  {!allDay && (
                    <Col lg="12">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">Start Time</label>
                            <TimePicker
                              defaultValue={startAt}
                              onChangeTime={(startAt: string) => {
                                setStartAt(startAt);
                              }}
                              minuteStep={30}
                              disabled={isDisabled}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> End Time</label>
                            <TimePicker
                              defaultValue={endAt}
                              onChangeTime={(endAt: string) => {
                                setEndAt(endAt);
                              }}
                              minuteStep={30}
                              disabled={isDisabled}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              )}
              <Row>
                <Col md="12 text-center">
                  {formEventType === 'calendar' && scheduleId ? (
                    <AddToCalendar
                      displayItemIcons={false}
                      event={actualEvent}
                      listItems={[{ apple: 'Apple Calendar' }, { google: 'Google' }]}
                      buttonLabel="Add to my calendar"
                    />
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              {!isDisabled && (
                <Row className="mt-5">
                  <Col md="4 text-center">
                    <Button color="primary" type="submit" size="lg">
                      Save
                    </Button>
                  </Col>
                  <Col md="4 text-center">
                    {scheduleId ? (
                      <Button
                        onClick={() => {
                          deleteItemAlert(scheduleId);
                        }}
                        color="danger"
                        size="lg"
                      >
                        Delete
                      </Button>
                    ) : (
                      ''
                    )}
                  </Col>
                  <Col md="4 text-center">
                    <Button color="secondary" size="lg" onClick={changeonModalStatus}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default SchoolScheduleEventForm;

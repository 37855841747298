/* eslint-disable max-lines */
import { Button, Card, CardHeader, CardBody, Container, Row, Col, Modal } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import SchoolScheduleEvents from '../../../services/SchoolScheduleEvents';
import CommonHeader from '../../../components/Headers/CommonHeader';
import React from 'react';
import SchedulleSettingsModal from '../../../views/pages/Schedule/SchedulleSettingsModal';

import FullCalendar, { EventClickArg, EventContentArg, DatesSetArg } from '@fullcalendar/react';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ReactTooltip from 'react-tooltip';

import moment from 'moment';

import SchoolScheduleEventForm from './SchoolScheduleEventForm';
import isAdmin from '../../../helpers/isAdmin';
import isDirector from '../../../helpers/isDirector';
import isEducationDirectorAdminAssistant from '../../../helpers/isEducationDirectorAdminAssistant';
import { isMobile } from 'react-device-detect';

const SchoolScheduleEventImportList = () => {
  const [lunchRef] = useState<any>(React.createRef());
  // const [lunchRef, setLunchRef] = useState<{ current?: FullCalendar }>();
  // const [snackRef, setSnackRef] = useState<{ current?: FullCalendar }>();
  const [snackRef] = useState<any>(React.createRef());

  const [schoolId, setSchoolId] = useState('');
  const [settingsModal, setSettingsModal] = useState(false);
  const { token, currentSchool, user }: any = useContext(AuthContext);

  const [scheduleId, setScheduleId] = useState('');
  const [scheduleForm, setScheduleForm] = useState(false);
  const pageTitle = scheduleId ? 'Update' : 'Create';
  const [dateClick, setDateClick] = useState('');
  const [formEventType, setFormEventType] = useState('calendar');

  const [snackCalendar, setSnackCalendar] = useState<{ startAt: string; endAt: string; events: [] }>();
  const [lunchCalendar, setLunchCalendar] = useState<{ startAt: string; endAt: string; events: [] }>();
  const [eventCalendar, setEventCalendar] = useState<{ startAt: string; endAt: string; events: [] }>();

  const canEdit = isAdmin(user) || isDirector(user) || isEducationDirectorAdminAssistant(user);

  const handleScheduleCreated = () => {
    if (formEventType === 'calendar') {
      setCalendarEvents(formEventType, eventCalendar?.startAt || '', eventCalendar?.endAt || '');
    }
    if (formEventType === 'lunch') {
      setCalendarEvents(formEventType, lunchCalendar?.startAt || '', lunchCalendar?.endAt || '');
    }
    if (formEventType === 'snack') {
      setCalendarEvents(formEventType, snackCalendar?.startAt || '', snackCalendar?.endAt || '');
    }
  };

  const renderEventContent = (type: string, eventContent: EventContentArg) => {
    const objectTitle = JSON.parse(eventContent.event.title);
    let innerEventContent;

    switch (type) {
      case 'calendar':
        innerEventContent = (
          <>
            <p className="m-0 text-center">
              <b style={{ fontWeight: 'bold' }}>{objectTitle.name}</b>
            </p>
            {!eventContent.event.allDay && (
              <p className="m-0 text-center">
                {moment.utc(eventContent.event.start).format('hh:mma')} -{' '}
                {moment.utc(eventContent.event.end).format('hh:mma')}
              </p>
            )}
            {objectTitle.isClosureSchool && (
              <p className="m-0 text-center" style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                School Closed
              </p>
            )}
          </>
        );
        break;
      case 'lunch':
        innerEventContent = (
          <>
            <p className="m-0 text-center" style={{ fontWeight: 'bold' }}>
              {objectTitle.protein}
            </p>
            <p className="m-0 text-center" style={{ fontWeight: 'bold' }}>
              {objectTitle.vegetable}
            </p>
            <p className="m-0 text-center" style={{ fontWeight: 'bold' }}>
              {objectTitle.fruits}
            </p>
            <p className="m-0 text-center" style={{ fontWeight: 'bold' }}>
              {objectTitle.drink}
            </p>
          </>
        );
        break;
      case 'snack':
        innerEventContent = (
          <>
            <p className="m-0">
              <b style={{ fontWeight: 'bold' }}>Morning: </b>
              {objectTitle.am}
            </p>
            <p className="m-0">
              <b style={{ fontWeight: 'bold' }}>Afternoon: </b>
              {objectTitle.pm}
            </p>
          </>
        );
        break;
      default:
        innerEventContent = <></>;
    }
    return (
      <>
        <div data-tip={eventContent.event.extendedProps.observations}>{innerEventContent}</div>
        <ReactTooltip />
      </>
    );
  };

  const renderEventsCalendarContent = (eventContent: EventContentArg) => {
    return renderEventContent('calendar', eventContent);
  };

  const renderEventsSnackContent = (eventContent: EventContentArg) => {
    return renderEventContent('snack', eventContent);
  };

  const renderEventsLunchContent = (eventContent: EventContentArg) => {
    return renderEventContent('lunch', eventContent);
  };

  const alterDatesSet = (type: string, args: DatesSetArg) => {
    setCalendarEvents(
      type,
      moment.utc(args.start).format('YYYY-MM-DD HH:mm:ss'),
      moment.utc(args.end).format('YYYY-MM-DD HH:mm:ss'),
    );
  };

  const alterDatesSetCalendar = (args: DatesSetArg) => {
    if (moment.utc(eventCalendar?.startAt).isBefore(moment.utc(args.start))) {
      lunchRef?.current?.getApi().next();
      snackRef?.current?.getApi().next();
    } else {
      lunchRef?.current?.getApi().prev();
      snackRef?.current?.getApi().prev();
    }
    alterDatesSet('calendar', args);
  };

  const alterDatesSetLunch = (args: DatesSetArg) => {
    alterDatesSet('lunch', args);
  };

  const alterDatesSetSnack = (args: DatesSetArg) => {
    alterDatesSet('snack', args);
  };

  const toggleScheduleForm = () => {
    setScheduleForm(!scheduleForm);
  };

  const modalChange = () => {
    setScheduleForm(!scheduleForm);
    if (scheduleForm == true) {
      //getScheduleId();
    }
  };

  const handleSnackEventClick = (clickInfo: EventClickArg) => {
    setFormEventType('snack');
    setScheduleId(clickInfo.event.id);
    modalChange();
  };

  const handleLunchEventClick = (clickInfo: EventClickArg) => {
    setFormEventType('lunch');
    setScheduleId(clickInfo.event.id);
    modalChange();
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    setFormEventType('calendar');
    if (clickInfo.event.classNames[0] == 'schedule-calendar-event-red') {
      //console.log(clickInfo.event.classNames);
    } else {
      setScheduleId(clickInfo.event.id);
      modalChange();
    }
  };

  const handleSnackDateClick = (selectInfo: DateClickArg) => {
    setFormEventType('snack');
    const date = new Date(selectInfo.date).toISOString().replace(/T.*$/, ''); // YYYY-MM-DD
    setScheduleId('');
    setDateClick(date);

    const snackInDate: any = snackCalendar?.events.find(
      (item: any) => new Date(item.start).toISOString().replace(/T.*$/, '') === date,
    );
    if (snackInDate !== undefined) {
      setScheduleId(snackInDate.id);
    }
    modalChange();
  };

  const handleLunchDateClick = (selectInfo: DateClickArg) => {
    setFormEventType('lunch');
    const date = new Date(selectInfo.date).toISOString().replace(/T.*$/, ''); // YYYY-MM-DD
    setScheduleId('');
    setDateClick(date);

    const lunchInDate: any = lunchCalendar?.events.find(
      (item: any) => new Date(item.start).toISOString().replace(/T.*$/, '') === date,
    );
    if (lunchInDate !== undefined) {
      setScheduleId(lunchInDate.id);
    }
    modalChange();
  };

  const handleDateClick = (selectInfo: DateClickArg) => {
    setFormEventType('calendar');
    const date = new Date(selectInfo.date).toISOString().replace(/T.*$/, ''); // YYYY-MM-DD
    setScheduleId('');
    setDateClick(date);
    modalChange();
  };

  const setCalendarEvents = async (type: string, startAt: string, endAt: string) => {
    startAt = moment.utc(startAt).startOf('day').toISOString();
    endAt = moment.utc(endAt).endOf('day').toISOString();
    try {
      const filters = {
        type: type,
        schoolId: schoolId || currentSchool.value,
        startAt: startAt,
        endAt: endAt,
      };
      const result = await SchoolScheduleEvents(token).getEventsBySchoolIdAndMonth(filters);
      const dataResult = result.data || [];
      if (type === 'calendar') {
        setEventCalendar({
          startAt,
          endAt,
          events: dataResult,
        });
      }
      if (type === 'lunch') {
        setLunchCalendar({
          startAt,
          endAt,
          events: dataResult,
        });
      }
      if (type === 'snack') {
        setSnackCalendar({
          startAt,
          endAt,
          events: dataResult,
        });
      }
    } catch (e: any) {
      toast.error('No Events found');
    }
  };

  useEffect(() => {
    if (eventCalendar !== undefined) {
      setCalendarEvents('calendar', eventCalendar?.startAt || '', eventCalendar?.endAt || '');
      setCalendarEvents('lunch', eventCalendar?.startAt || '', eventCalendar?.endAt || '');
      setCalendarEvents('snack', eventCalendar?.startAt || '', eventCalendar?.endAt || '');
    }
  }, [schoolId]);

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
  }, []);

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
  }, [currentSchool]);

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row className="home-waitingList">
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="9">
                    <h3 className="mb-0">Monthly Info</h3>
                  </Col>

                  <Col xs="3" style={{ textAlign: 'right' }}>
                    {canEdit && !isMobile && (
                      <Link to="/monthly-info/Import">
                        <Button color="primary" className="float-right">
                          <i className="fas fa-file-import"></i>&nbsp;Import Data
                        </Button>
                      </Link>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-1">
                <Row>
                  <Col md="12">
                    <div className="calendar-app-main calendar-monthly-info">
                      <h2 className="text-center pb-2">School Events</h2>
                      <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                        headerToolbar={{
                          left: 'prev,next',
                          center: 'title',
                          right: 'today',
                        }}
                        eventTimeFormat={{
                          hour: 'numeric',
                          minute: '2-digit',
                          meridiem: 'short',
                        }}
                        initialView="dayGridMonth"
                        editable={false}
                        selectable={false}
                        selectMirror={true}
                        dayMaxEvents={false}
                        weekends={false}
                        events={eventCalendar?.events}
                        eventContent={renderEventsCalendarContent} // custom render function
                        eventClick={handleEventClick}
                        datesSet={alterDatesSetCalendar}
                        dateClick={handleDateClick}
                      />
                    </div>
                    <div className="calendar-app-lunch calendar-monthly-info mt-5">
                      <h2 className="text-center pb-2">Lunch Menu</h2>
                      <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                        headerToolbar={{
                          left: '',
                          center: 'title',
                          right: '',
                        }}
                        eventTimeFormat={{
                          hour: 'numeric',
                          minute: '2-digit',
                          meridiem: 'short',
                        }}
                        initialView="dayGridMonth"
                        editable={false}
                        selectable={false}
                        selectMirror={true}
                        dayMaxEvents={false}
                        weekends={false}
                        events={lunchCalendar?.events}
                        eventContent={renderEventsLunchContent} // custom render function
                        eventClick={handleLunchEventClick}
                        datesSet={alterDatesSetLunch}
                        dateClick={handleLunchDateClick}
                        ref={lunchRef}
                      />
                    </div>
                    <div className="calendar-app-snack calendar-monthly-info mt-5">
                      <h2 className="text-center pb-2">Snack Menu</h2>
                      <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                        headerToolbar={{
                          left: '',
                          center: 'title',
                          right: '',
                        }}
                        eventTimeFormat={{
                          hour: 'numeric',
                          minute: '2-digit',
                          meridiem: 'short',
                        }}
                        initialView="dayGridMonth"
                        editable={false}
                        selectable={false}
                        selectMirror={true}
                        dayMaxEvents={false}
                        weekends={false}
                        events={snackCalendar?.events}
                        eventContent={renderEventsSnackContent} // custom render function
                        eventClick={handleSnackEventClick}
                        datesSet={alterDatesSetSnack}
                        dateClick={handleSnackDateClick}
                        ref={snackRef}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <SchedulleSettingsModal
              isOpen={settingsModal}
              toggleModal={() => {
                setSettingsModal(!settingsModal);
              }}
            />
          </Col>
        </Row>
      </Container>

      <Modal className="modal-dialog-centered" isOpen={scheduleForm} toggle={() => toggleScheduleForm()}>
        <div className="modal-header">
          <h5 className="modal-title" id="Label">
            {`${pageTitle} Schedule`}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleScheduleForm()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <SchoolScheduleEventForm
            isOpen={scheduleForm}
            scheduleId={scheduleId}
            onModalStatus={modalChange}
            dateClick={dateClick}
            onCreateSchedule={handleScheduleCreated}
            formEventType={formEventType}
          />
        </div>
      </Modal>
    </>
  );
};
export default SchoolScheduleEventImportList;

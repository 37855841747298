/* eslint-disable max-lines */
const routes = [
  {
    path: '/home',
    name: 'Home',
    icon: 'fas fa-home text-body',
    layout: '/logged',
    isItemMenu: true,
    isRestricted: true,
  },
  // {
  //   path: '/users/:userId/profile',
  //   name: 'My Profile',
  //   icon: 'ni ni-single-02 text-primary',
  //   layout: '/logged',
  //   isItemMenu: true,
  //   isRestricted: true,
  //   moduleName: 'Users',
  //   functionName: 'Show',
  //   onlyForGroups: ['Staff', 'Parents', 'Teachers'],
  // },
  {
    path: '/users/:userId/profile?tab=children',
    name: 'My Student',
    icon: 'fas fa-baby text-purple',
    layout: '/logged',
    isItemMenu: true,
    isRestricted: true,
    moduleName: 'Users',
    functionName: 'Show',
    onlyParent: true,
    onlyForGroups: ['Manager', 'Director'],
  },
  {
    path: '/register',
    name: 'Register',
    icon: 'fas fa-book-open text-pink',
    layout: '/auth',
    isItemMenu: false,
  },
  {
    path: '/billing',
    name: 'Billing',
    icon: 'fas fa-dollar-sign text-green',
    layout: '/logged',
    isItemMenu: true,
    isRestricted: true,
    moduleName: 'Billing',
    functionName: 'List',
    // onlyAdmin: true,
    onlyForGroups: ['Manager', 'Director', 'EducationDirectorAdminAssistant', 'UpperManagement'],
  },
  // TODO return Billing for Parents
  // {
  //   path: '/billing-parents/:userId',
  //   name: 'Billing',
  //   icon: 'fas fa-dollar-sign text-green',
  //   layout: '/logged',
  //   isItemMenu: true,
  //   isRestricted: true,
  //   moduleName: 'Billing',
  //   functionName: 'ListByParent',
  //   onlyParentPaying: true,
  // },
  {
    path: '/curriculum',
    name: 'Curriculum',
    icon: ' ni ni-books text-info',
    layout: '/logged',
    isItemMenu: true,
    isRestricted: true,
    moduleName: 'Documents',
    functionName: 'List',
    onlyForGroups: [
      'Staff',
      'Manager',
      'Classroom',
      'Teachers',
      'Director',
      'EducationDirectorAdminAssistant',
      'UpperManagement',
    ],
  },
  {
    path: '/resource',
    name: 'Resources',
    icon: 'fas fa-poll-h text-orange',
    layout: '/logged',
    isItemMenu: true,
    isRestricted: true,
    moduleName: 'Documents',
    functionName: 'List',
    onlyForGroups: [
      'Staff',
      'Manager',
      'Classroom',
      'Teachers',
      'Director',
      'EducationDirectorAdminAssistant',
      'UpperManagement',
      'Parents',
    ],
  },
  {
    path: '/pto',
    name: 'PTO',
    icon: 'fas fa-user text-yellow',
    layout: '/logged',
    isItemMenu: true,
    isRestricted: true,
    moduleName: 'Staff',
    functionName: 'List',
    onlyForGroups: ['Staff', 'Teachers'],
  },
  {
    path: '/documents',
    name: 'Documents',
    icon: 'fas fa-poll-h text-yellow',
    layout: '/logged',
    isItemMenu: true,
    isRestricted: true,
    moduleName: 'Documents',
    functionName: 'List',
    onlyParent: true,
  },
  {
    path: '/class',
    name: 'Class',
    icon: 'fas fa-chalkboard-teacher text-info',
    layout: '/logged',
    isItemMenu: true,
    isRestricted: true,
    moduleName: 'Class',
    functionName: 'List',
    onlyAdmin: false,
    onlyForGroups: [
      'Manager',
      'Classroom',
      'Director',
      'EducationDirectorAdminAssistant',
      'UpperManagement',
      'Teachers',
    ],
  },
  {
    path: '/kids',
    name: 'Students',
    icon: 'fas fa-baby text-success',
    layout: '/logged',
    isItemMenu: true,
    isRestricted: true,
    moduleName: 'Kids',
    functionName: 'List',
    onlyAdmin: false,
    onlyForGroups: [
      'Manager',
      'Classroom',
      'Director',
      'EducationDirectorAdminAssistant',
      'UpperManagement',
      'Teachers',
    ],
  },
  // {
  //   path: '/users',
  //   name: 'Users',
  //   icon: 'fas fa-user text-red',
  //   layout: '/logged',
  //   isItemMenu: true,
  //   isRestricted: true,
  //   moduleName: 'Users',
  //   functionName: 'List',
  //   onlyForGroups: ['Manager'],
  // },
  {
    path: '/monthly-info',
    name: 'Monthly Info',
    icon: 'fas fa-calendar text-blue',
    layout: '/logged',
    isItemMenu: true,

    isRestricted: true,
    moduleName: 'schoolScheduleEvent',
    functionName: 'ListForCalendar',
    onlyForGroups: ['Manager', 'Director', 'UpperManagement', 'Parents'],
  },
  {
    path: '/support',
    name: 'Support',
    icon: 'fas fa-headset text-indigo',
    layout: '/logged',
    isItemMenu: true,
    isRestricted: true,
    onlyForGroups: ['Manager', 'EducationDirectorAdminAssistant', 'UpperManagement'],
  },
  {
    path: '/support/new',
    name: 'Support',
    icon: 'fas fa-headset text-indigo',
    layout: '/logged',
    isItemMenu: true,
    isRestricted: true,
    onlyForGroups: ['Staff', 'Parents', 'Classroom', 'Teachers'],
  },
  {
    path: '/directory',
    name: 'Staff',
    icon: 'fas fa-user text-cyan',
    layout: '/logged',
    isItemMenu: true,
    isRestricted: true,
    moduleName: 'Staff',
    isCollapsed: true,
    onlyForGroups: ['Manager', 'Director', 'EducationDirectorAdminAssistant', 'UpperManagement'],
    childs: [
      {
        path: '/directory',
        name: 'Directory',
        layout: '/logged',
        isItemMenu: true,
        isRestricted: true,
        moduleName: 'HireAgreements',
        functionName: 'List',
      },
      // {
      //   path: '/payroll',
      //   name: 'Payroll',
      //   layout: '/logged',
      //   isItemMenu: true,
      //   isRestricted: true,
      //   moduleName: 'Staff',
      // },
      {
        path: '/pto',
        name: 'PTO',
        layout: '/logged',
        isItemMenu: true,
        isRestricted: true,
        moduleName: 'BlackoutDate',
        functionName: 'Show',
      },
    ],
  },

  {
    path: '/messaging',
    name: 'Messaging',
    icon: 'fas fa-comment text-indigo',
    layout: '/logged',
    isItemMenu: true,
    isRestricted: true,
    onlyAdmin: true,
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: 'fas fa-cog text-info',
    layout: '/logged',
    isItemMenu: true,
    isRestricted: true,
    moduleName: 'Settings',
    isCollapsed: true,
    onlyForGroups: ['Manager', 'Director'],
    childs: [
      {
        path: '/promo-codes',
        name: 'Promo Codes',
        icon: 'fa fa-tag text-pink',
        layout: '/logged',
        isItemMenu: true,
        isRestricted: true,
        moduleName: 'PromoCodes',
        functionName: 'List',
      },
      {
        path: '/reports',
        name: 'Reports',
        icon: 'fa fa-file-export text-info',
        layout: '/logged',
        isItemMenu: true,
        isRestricted: true,
      },
      {
        path: '/franchisee',
        name: 'Franchisee',
        icon: 'fas fa-briefcase text-info',
        layout: '/logged',
        isItemMenu: true,
        isRestricted: true,
        onlyForGroups: ['Manager'],
        moduleName: 'Franchisee',
        functionName: 'List',
      },
      {
        path: '/school',
        name: 'School',
        icon: 'fas fa-school text-pink',
        layout: '/logged',
        isItemMenu: true,
        isRestricted: true,
        onlyForGroups: ['Manager', 'UpperManagement'],
        moduleName: 'School',
        functionName: 'List',
      },
      {
        path: '/employee-settings',
        name: 'Employee Settings',
        layout: '/logged',
        isItemMenu: true,
        isRestricted: true,
        onlyForGroups: ['Manager', 'UpperManagement'],
      },
      {
        path: '/users',
        name: 'Users',
        layout: '/logged',
        isItemMenu: true,
        isRestricted: true,
        moduleName: 'Users',
        functionName: 'List',
      },
      {
        path: '/imports',
        name: 'Imports',
        layout: '/logged',
        isItemMenu: true,
        isRestricted: true,
        moduleName: 'Import',
        functionName: 'Form',
      },
    ],
  },
];
export default routes;

/* eslint-disable max-lines */
import {
  Card,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import ReactDatetime from 'react-datetime';
import moment, { Moment } from 'moment';
import { useEffect, useContext, useState, useMemo } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import Class from '../../../services/Class';

type Props = {
  index: number;
  firstName: string;
  lastName: string;
  birthday: string;
  startDate: string;
  schoolId: string;
  classId: string;
  classType: string;
  setFirstName: (index: number, value: string) => void;
  setLastName: (index: number, value: string) => void;
  setBirthday: (index: number, value: string) => void;
  setClassType: (index: number, value: string) => void;
  setStartDate: (index: number, value: string) => void;
  setClassId: (index: number, value: string) => void;
  setClassTypeName: (index: number, value: string) => void;
};

const ChildrenForm = ({
  index,
  firstName,
  lastName,
  birthday,
  startDate,
  schoolId,
  classId,
  classType,
  setFirstName,
  setLastName,
  setBirthday,
  setStartDate,
  setClassType,
  setClassId,
  setClassTypeName,
}: Props) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [classList, setClassList] = useState([]);
  const validStartDate = (current: Moment) => {
    const today = moment();
    return current.isAfter(today);
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
  };

  const getClassTypeList = () => {
    const classTypes = {};
    classList.forEach((c: any) =>
      c.classPriceSheet?.forEach((p: any) => {
        (classTypes as any)[p.classType?.id] = p.classType;
      }),
    );
    return Object.values(classTypes);
  };

  useMemo(() => {
    if (!birthday || !schoolId) return;
    if (!moment(birthday).isValid()) return;
    const loadClassList = async (schoolId: string, age: number) => {
      setLoading(true);
      setClassList(
        (await Class(token).listBySchoolForEnroll(schoolId)).data?.filter(
          (c: any) => c.startAge <= age && c.endAge >= age,
        ),
      );
      setLoading(false);
    };
    loadClassList(schoolId, moment().diff(birthday, 'year'));
  }, [birthday, schoolId, token, setClassList]);

  useEffect(() => {
    if (!classId) return;
  }, [classId]);

  return (
    <>
      <Container fluid>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            <CardBody style={{ padding: '1rem', paddingBottom: 0 }}>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-firstName">
                      First Name *
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-firstName"
                      placeholder="First Name"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(index, e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-lastName">
                      Last Name *
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-lastName"
                      placeholder="Last Name"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(index, e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Birthday</label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Birthday',
                          required: true,
                        }}
                        onChange={(newValue) => {
                          setBirthday(index, `${newValue}`);
                        }}
                        timeFormat={false}
                        value={`${birthday}`}
                        dateFormat={'MM/DD/yyyy'}
                        closeOnSelect={true}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">When would you like to start? *</label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Start Date',
                        }}
                        onChange={(newValue) => {
                          setStartDate(index, `${newValue}`);
                        }}
                        timeFormat={false}
                        value={`${startDate}`}
                        dateFormat={'MM/DD/yyyy'}
                        isValidDate={validStartDate}
                        closeOnSelect={true}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-1">
                    <label className="form-control-label">Class *</label>
                    <Input
                      type="select"
                      defaultValue={classId || ''}
                      onChange={(e) => setClassId(index, e.target.value)}
                      required
                    >
                      <option value=""></option>
                      {classList.map((a: any, index: number) => {
                        return (
                          <option value={a.id} key={`class-id-list-${index}`}>
                            {a.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                  {!loading && classList.length === 0 && (
                    <div className="alert alert-danger" role="alert">
                      YOU CANNOT ENROLL. There is not a class for you to enroll in at this time. Please reach out to the
                      school for more information or help
                    </div>
                  )}
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-1">
                    <label className="form-control-label">Class Type *</label>
                    <Input
                      type="select"
                      defaultValue={classType || ''}
                      onChange={(e: any) => {
                        setClassTypeName(index, e.target.options[e.target.selectedIndex].innerHTML);
                        setClassType(index, e.target.value);
                      }}
                      required
                    >
                      <option value=""></option>
                      {getClassTypeList().map((a: any, index: number) => {
                        return (
                          <option value={a.id} key={`class-type-${index}`}>
                            {a.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                  {!loading && classList.length !== 0 && getClassTypeList().length === 0 && (
                    <div className="alert alert-danger" role="alert">
                      YOU CANNOT ENROLL. There is not a class for you to enroll in at this time. Please reach out to the
                      school for more information or help
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default ChildrenForm;

import http from '../helpers/http';
import { FilteredOptionsInterface, makeQueryParamsForRequest } from '../entities/FilteredOptions';
import SchoolInterface from '../interfaces/SchoolInterface';
import SchoolAddressInterface from '../interfaces/SchoolAddressInterface';

const School = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get('/schools', {
        headers,
        params,
      });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`/schools/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: Partial<SchoolInterface>) => {
      const result = await http.post(`/schools`, body, { headers });
      return result.data;
    },
    update: async (id: string, body: Partial<SchoolInterface>) => {
      const result = await http.put(`/schools/${id}`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/schools/${id}`, {
        headers,
      });
      return result.data;
    },
    //Address
    showAddress: async (schoolId: string) => {
      const result = await http.get(`/schools/${schoolId}/address`, {
        headers,
      });
      return result.data;
    },
    createAddress: async (body: Partial<SchoolAddressInterface>) => {
      const result = await http.post(`/schools/address`, body, { headers });
      return result.data;
    },
    updateAddress: async (id: string, body: Partial<SchoolAddressInterface>) => {
      const result = await http.put(`/schools/${id}/address`, body, { headers });
      return result.data;
    },
    listSelect: async (params?: {}) => {
      const result = await http.get(`/schools/select/all`, {
        headers,
        params,
      });
      return result.data;
    },
    changeTokenSchool: async (body: { id: string }) => {
      const result = await http.patch(`/schools/tokenSchool`, body, { headers });
      return result.data;
    },
    file: async (id: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/schools/${id}/file`, body, headers);
      return result.data;
    },
    getSchoolPublic: async (uri: string) => {
      const result = await http.get(`/schools/${uri}/public`);
      return result.data;
    },
    getSchoolStripeCurrent: async (id: string) => {
      const result = await http.get(`/schools/${id}/stripe/current`);
      return result.data;
    },
    updateTourList: async (id: string, tourList: boolean) => {
      const result = await http.put(`/schools/update-tour-list/${id}`, { tourList }, { headers });
      return result.data;
    },
  };
};

export default School;

import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import TimelineInterface from '../../interfaces/UtilsInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useContext } from 'react';
import TimelineService from '../../services/Timeline';
import { Context as AuthContext } from '../../contexts/AuthContext';
import * as FileSaver from 'file-saver';
import moment from 'moment';

const TimelineItem = (props: TimelineInterface) => {
  const { token, currentSchool }: any = useContext(AuthContext);

  const forceDownload = async (attachment: any) => {
    try {
      const result = await TimelineService(token).downloadAttach(attachment.id);
      const data = new Blob([Buffer.from(result.content)]);
      FileSaver.saveAs(data, 'image.jpg');
    } catch (e: any) {
      // toast.error(e?.response?.data?.message || 'Invalid Kids ID');
    }
  };

  const downloadAttachments = useCallback((attachments) => {
    attachments.forEach((attachment: any) => {
      forceDownload(attachment);
    });
  }, []);
  let time = '-';
  if (props?.time && currentSchool?.timezone) {
    time = moment(props.time).tz(currentSchool.timezone).format('MM/DD/YYYY HH:mm A');
  }
  if (props?.time && !currentSchool?.timezone) {
    time = moment(props.time).format('MM/DD/YYYY HH:mm A');
  }
  return (
    <>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'rgba(255,255,255,1)', color: '#67748e' }}
        contentArrowStyle={{ borderRight: '7px solid rgba(255,255,255,1)' }}
        iconClassName={`${props.status || 'bg-info'} text-white`}
        icon={<FontAwesomeIcon icon={props.icon} />}
      >
        <div className="timeline-date-container">
          <span className="timeline-date">{time}</span>
        </div>
        <div className="clearfix">
          <h2 className="vertical-timeline-element-title float-left">{props.name}</h2>
          <div className="float-right">
            {props.canDownloadAttachs != false && props.attachments && props.attachments.length > 0 && (
              <a className="btn btn-sm btn-primary" onClick={() => downloadAttachments(props.attachments)}>
                <i className="fa fa-download"></i>
              </a>
            )}
          </div>
        </div>
        {props?.name === 'Food' && (
          <p>
            <b>{props?.options?.mealType}</b>:{props?.options?.food}
            {props?.options?.foodDescribe !== '' && <span>({props?.options?.foodDescribe})</span>}
            {props?.options?.childAte && <span>[Ate {props?.options?.childAte || '-'}]</span>}
          </p>
        )}
        <p className="">{props.description}</p>
        {props.children}
        <div>{props.content}</div>
      </VerticalTimelineElement>
    </>
  );
};

export default TimelineItem;

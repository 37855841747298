export const validStatus = {
  ACTIVE: { value: 'ACTIVE', color: 'success' },
  BLOCKED: { value: 'BLOCKED', color: 'danger' },
};

export enum statusEnum {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
}

export interface SchoolInterface {
  id: string;
  name: string;
  franchiseeId: string;
  status: statusEnum;
  uri: string;
  waitingList?: Boolean;
  timeOperationStart?: string;
  timeOperationEnd?: string;
  stripLikeKeyPk: string;
  stripLiveSecretSk: string;
  stripTestKeyPk: string;
  stripTestSecretSk: string;
  createdAt: Date;
  updatedAt: Date;
  timezone?: string;
  tourList: boolean;
}

export default SchoolInterface;

/* eslint-disable max-lines */
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  Modal,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { useContext, useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import CommonHeader from '../../../../components/Headers/CommonHeader';
import ReactDatetime from 'react-datetime';
import EmployeeService from '../../../../services/Employee';
import { FilteredOptionsInterface } from '../../../../entities/FilteredOptions';
import { PagedMetaInterface, createPagedMeta } from '../../../../entities/PagedMeta';
import { createOptionsPage } from '../../../../entities/OptionsPaged';
import DataTable from '../../../../components/Core/DataTable';
import FullCalendar, { EventContentArg } from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ReactTooltip from 'react-tooltip';
import moment, { Moment } from 'moment';
import CardInfo from '../../../../components/Cards/CardInfo';
import ModalContent from '../../../../components/Modals/Modal';
import PtoService from '../../../../services/Pto';
import PtoRequestDetail from '../PtoRequestDetail';
import PtoReproveConfirm from '../PtoReproveConfirm';
import { confirmAlert } from 'react-confirm-alert';
import BlackoutDatesForm from '../BlackoutDatesForm';
import BlackoutDate from '../../../../services/BlackoutDate';
import isAdmin from '../../../../helpers/isAdmin';
import confirmAlertHelper from '../../../../helpers/confirmAlert';
import isDirector from '../../../../helpers/isDirector';
import UserBalanceDetailForm from '../UserBalanceDetailForm';
import Holidays from '../../../../services/Holidays';
import PtoHollidayDetail from '../PtoHollidayDetail';
import BalanceCollumns from './datatableCollumns/BalanceCollumns';
import RequestCollumns from './datatableCollumns/RequestCollumns';
import ScheduledCollumns from './datatableCollumns/ScheduledCollumns';
import { DatesSetArg } from '@fullcalendar/react';
import PenddingRequestInfo from './PenddingRequestInfo';
import { isMobile } from 'react-device-detect';
import CardWidget from '../../../../components/Cards/CardWidget';
import isUpperManagment from '../../../../helpers/isUpperManagment';

const Pto = () => {
  const { token, currentSchool, user }: any = useContext(AuthContext);
  const isAdminUser = isAdmin(user) || isDirector(user) || isUpperManagment(user);
  const [activeTab, setActiveTab] = useState('1');
  const [isOpenTimeOff, setIsOpenTimeOff] = useState(false);
  const [requestDetailsData, setRequestDetailsData] = useState<any>();
  const [isRequestDetailsOpen, setIsRequestDetailsOpen] = useState(false);
  const [ptoId, setPtoId] = useState(null);
  const childRef = useRef<{ reloadData: any }>(null);

  const [modalBalanceOpen, setModalBalanceOpen] = useState(false);
  const [modalHollidayOpen, setModalHollidayOpen] = useState(false);
  const [balanceDetailData, setBalanceDetailData] = useState<any>();
  const [userBalanceDetailFormEditMode, setUserBalanceDetailFormEditMode] = useState(false);

  const reloadPendingRequestsData = () => {
    if (!childRef.current) {
      return;
    }
    childRef.current.reloadData();
  };

  const setFormDataIfIsOpened = (data?: any) => {
    if (data === undefined || data.constructor.name === 'SyntheticBaseEvent') return false;
    setPtoId(data.id || null);
    setSelectedEmployeeId(data.user?.id || null);
    setReason(data.reason);
    setStartDate(data.startDate);
    setEndDate(data.endDate || data.startDate);
    setHours(data.hours);
    setDescribe(data.describe);
    setTimeRequested('One or more Days');
    if (data.startDate === data.endDate || data.endDate === undefined) {
      setTimeRequested('Less than one day');
    }
  };

  const toggleTimeOff = (data?: any) => {
    setPtoId(null);
    setSelectedEmployeeId('');
    setPolicyId('');
    setReason('');
    setStartDate('');
    setEndDate('');
    setHours('');
    setDescribe('');
    setTimeRequested('');
    if (!isAdminUser || isMobile) {
      setSelectedEmployeeId(user.id);
    }
    setFormDataIfIsOpened(data);
    setIsOpenTimeOff(!isOpenTimeOff);
  };

  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
      border: '0',
    },
    navLinkClickedALter: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
      border: '0',
      borderBottom: '2px solid #FF7B00',
    },
  };

  const confirmRepprove = (e: any, id: string) => {
    if (data.constructor.name === 'SyntheticBaseEvent') e.preventDefault();

    confirmAlert({
      customUI: ({ onClose }) => {
        return <PtoReproveConfirm id={id} handleRepprove={handleRepprove} type="deny" onClose={onClose} />;
      },
    });
  };

  const confirmApprove = (e: any, id: string) => {
    if (data.constructor.name === 'SyntheticBaseEvent') e.preventDefault();

    confirmAlert({
      customUI: ({ onClose }) => {
        return <PtoReproveConfirm id={id} handleRepprove={handleApprove} type="approve" onClose={onClose} />;
      },
    });
  };

  const columnsBalance = BalanceCollumns({
    setBalanceDetailData,
    setUserBalanceDetailFormEditMode,
    setModalBalanceOpen,
  });

  const columnsRequests = RequestCollumns({
    setRequestDetailsData,
    setIsRequestDetailsOpen,
    user,
    confirmApprove,
    confirmRepprove,
  });

  const columnsScheduled = ScheduledCollumns({
    setRequestDetailsData,
    setIsRequestDetailsOpen,
    isAdminUser,
    confirmRepprove,
  });

  const [data, setData] = useState([] as any[]);
  const [scheduledData, setScheduledData] = useState([] as any[]);
  const [scheduledMonthData, setScheduledMonthData] = useState([] as any[]);
  const [events, setEvents] = useState([] as any[]);

  const [employees, setEmployees] = useState<any>([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<any>([]);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState<any>(null);

  const [loading, setLoading] = useState(false);

  const [policyId, setPolicyId] = useState('');
  const [policyName, setPolicyName] = useState('');
  const [timeRequested, setTimeRequested] = useState('');
  const [reason, setReason] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [hours, setHours] = useState('');
  const [describe, setDescribe] = useState('');

  const [weekendsVisible, setWeekendsVisible] = useState(false);
  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  });
  const [requestFilteredOptions, SetRequestFilteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  });
  const [toggleModal, setToggleModal] = useState(false);
  const handleToggleModal = () => setToggleModal(!toggleModal);

  const [blackoutDatesModal, setBlackoutDatesModal] = useState(false);
  const handleToggleBlackoutDatesModal = () => {
    if (blackoutDatesModal === true) {
      setClickedBlackoutData({});
      setClickedBlackoutId('');
      setBlackoutDatesModalViewMode(false);
    }
    setBlackoutDatesModal(!blackoutDatesModal);
  };
  const [blackoutDates, setBlackoutDates] = useState([] as any[]);
  const [hollidayDates, setHollidayDates] = useState([] as any[]);
  const [clickedEvent, setClickedEvent] = useState({} as { data?: {}; event?: { groupId: string } });
  const [clickedBlackoutId, setClickedBlackoutId] = useState('');
  const [clickedHollidayId, setClickedHollidayId] = useState('');
  const [clickedHollidayData, setClickedHollidayData] = useState<any>();
  const [clickedBlackoutData, setClickedBlackoutData] = useState<any>();
  const [blackoutDatesModalViewMode, setBlackoutDatesModalViewMode] = useState(false);
  const [blackoutEvents, setBlackoutEvents] = useState([]);
  const [hollidayEvents, setHollidayEvents] = useState([]);
  const canEditRequest = isAdmin(user) || isDirector(user);
  const [eventsInfo, setEventsInfo] = useState({});

  const changeWeekendsVisible = () => {
    setWeekendsVisible(!weekendsVisible);
  };
  const renderEventContent = (eventContent: EventContentArg) => {
    return (
      <>
        <b>{eventContent.timeText}</b>
        <i data-tip={eventContent.event.extendedProps.observations}>{eventContent.event.title}</i>
        <ReactTooltip />
      </>
    );
  };

  useEffect(() => {
    loadList(filteredOptions);
    loadRequests();
    loadRequestsMonthScheduled();
  }, [filteredOptions, currentSchool]);

  useEffect(() => {
    loadRequestData();
  }, [requestFilteredOptions]);

  useEffect(() => {
    loadRequests();
    loadRequestsMonthScheduled();
    loadRequestData();
  }, []);

  useEffect(() => {
    const selectedEmployee = data.find((employee: any) => employee.userId == selectedEmployeeId);
    setPolicyId(selectedEmployee?.ptoPolicyId);
    setPolicyName(selectedEmployee?.ptoPolicy.name);
    setSelectedEmployeeData(selectedEmployee);
  }, [selectedEmployeeId]);

  const loadList = async (filteredOptions: FilteredOptionsInterface) => {
    try {
      setLoading(true);
      const newFilteredOptions = JSON.parse(JSON.stringify(filteredOptions));
      if (!newFilteredOptions?.options) {
        newFilteredOptions.options = createOptionsPage(pagedMeta);
      }
      const filter = {
        field: 'schoolId',
        operation: 'hash',
        value: currentSchool.value,
      };
      if (!Array.isArray(newFilteredOptions?.filters)) {
        newFilteredOptions.filters = [];
      }
      newFilteredOptions.filters.push(filter);
      if (!isAdminUser) {
        newFilteredOptions.filters.push({
          field: 'userId',
          operation: 'hash',
          value: user.id,
        });
      }
      newFilteredOptions.filters.push({
        field: 'status',
        operation: '=',
        value: 'ACTIVE',
      });
      const result = await EmployeeService(token || '').list(newFilteredOptions);
      const data = result.data;
      setData(data);
      setEmployees(data);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const filterByUserOrAdmin = (userId: string) => {
    if (isAdminUser) {
      return true;
    }
    return userId == user.id;
    //&& !isAdminUser && value.userId === user.id
  };

  const loadRequests = async (
    start: Moment = moment().startOf('month').startOf('week'),
    end: Moment = moment().endOf('month').endOf('week'),
  ) => {
    const result = await PtoService(token).timeOffRequestListByDates(currentSchool.value, start, end);

    const scheduledList = result?.data?.filter((value: any) => value.status === 'APPROVE');
    const eventsListData = result?.data?.filter(
      (value: any) => value.status !== 'REPPROVE' && filterByUserOrAdmin(value.userId),
    );

    const eventsList = [];
    if (eventsListData?.length) {
      for (const b of eventsListData) {
        if (!b?.startDate || !b?.endDate) continue;
        if (moment(b?.endDate).utc() > moment(b?.startDate).utc()) {
          let currentDate = moment(b?.startDate).utc();
          while (moment(b?.endDate).utc() >= currentDate) {
            eventsList.push({
              id: b.id,
              title: `${b?.user?.firstName} ${b?.user?.lastName}`,
              start: currentDate.format('YYYY-MM-DD'),
              end: currentDate.format('YYYY-MM-DD'),
              color: b?.status === 'PENDDING' ? '#bbbbbb' : '#14b11c',
              groupId: 'event',
            });
            currentDate = currentDate.add(1, 'day');
          }
        }
        if (moment(b?.endDate).utc().format('YYYY-MM-DD') === moment(b?.startDate).utc().format('YYYY-MM-DD')) {
          eventsList.push({
            id: b.id,
            title: `${b?.user?.firstName} ${b?.user?.lastName}`,
            start: moment(b?.startDate).utc().format('YYYY-MM-DD'),
            end: moment(b?.endDate).utc().format('YYYY-MM-DD'),
            color: b?.status === 'PENDDING' ? '#bbbbbb' : '#14b11c',
            groupId: 'event',
          });
        }
      }
    }

    const blackouts = await BlackoutDate(token).list(currentSchool.value);
    const blackoutsData = blackouts?.data || [];
    setBlackoutEvents(blackoutsData);
    const blackoutDatesArray = [];
    if (blackoutsData?.length) {
      for (const b of blackoutsData) {
        if (!b?.startDate || !b?.endDate) continue;
        if (moment(b?.endDate).utc() > moment(b?.startDate).utc()) {
          let currentDate = moment(b?.startDate).utc();
          while (moment(b?.endDate).utc() >= currentDate) {
            blackoutDatesArray.push(currentDate.format('YYYY-MM-DD'));
            eventsList.push({
              id: b.id,
              title: b?.reason || '',
              start: currentDate.format('YYYY-MM-DD'),
              end: currentDate.format('YYYY-MM-DD'),
              color: '#172b4d',
              groupId: 'blackout',
              // display: 'background',
            });
            currentDate = currentDate.add(1, 'day');
          }
        }
        if (moment(b?.endDate).utc().format('YYYY-MM-DD') === moment(b?.startDate).utc().format('YYYY-MM-DD')) {
          blackoutDatesArray.push(moment(b?.startDate).utc().format('YYYY-MM-DD'));
          eventsList.push({
            id: b.id,
            title: b?.reason || '',
            start: moment(b?.startDate).utc().format('YYYY-MM-DD'),
            end: moment(b?.endDate).utc().format('YYYY-MM-DD'),
            color: '#172b4d',
            groupId: 'blackout',
            // display: 'background',
          });
        }
      }
      setBlackoutDates(blackoutDatesArray);
    }

    const hollidays = await Holidays(token).list(parseInt(moment().format('YYYY')), currentSchool.value);
    const hollidaysData = hollidays?.data || [];
    setHollidayEvents(hollidaysData);
    const hollidayDatesArray = [];
    if (hollidaysData?.length) {
      for (const h of hollidaysData) {
        if (!h?.startDate || !h?.endDate) continue;
        if (moment(h?.endDate).utc() > moment(h?.startDate).utc()) {
          let currentDate = moment(h?.startDate).utc();
          while (moment(h?.endDate).utc() >= currentDate) {
            hollidayDatesArray.push(currentDate.format('YYYY-MM-DD'));
            eventsList.push({
              id: h.id,
              title: h?.name || '',
              start: currentDate.format('YYYY-MM-DD'),
              end: currentDate.format('YYYY-MM-DD'),
              color: '#e4605e',
              groupId: 'holliday',
              // display: 'background',
            });
            currentDate = currentDate.add(1, 'day');
          }
        }
        if (moment(h?.endDate).utc().format('YYYY-MM-DD') === moment(h?.startDate).utc().format('YYYY-MM-DD')) {
          hollidayDatesArray.push(moment(h?.startDate).utc().format('YYYY-MM-DD'));
          eventsList.push({
            id: h.id,
            title: h?.name || '',
            start: moment(h?.startDate).utc().format('YYYY-MM-DD'),
            end: moment(h?.endDate).utc().format('YYYY-MM-DD'),
            color: '#e4605e',
            groupId: 'holliday',
            // display: 'background',
          });
        }
      }
      setHollidayDates(hollidayDatesArray);
    }

    setEventsInfo({
      event: result.data,
      blackout: blackouts.data,
      holliday: hollidays.data,
    });

    // setRequestsData(requestedList);
    setScheduledData(scheduledList);
    // setPenddingData(penddingList);
    setEvents(eventsList);
  };

  const [requestDataTableProp, setRequestDataTableProps] = useState({ data: [], meta: { total: 0 } });
  const loadRequestData = async () => {
    setLoading(true);
    try {
      const currFilteredOption = JSON.parse(JSON.stringify(requestFilteredOptions));
      currFilteredOption.filters = [
        {
          field: 'status',
          operation: 'in',
          value: ['PENDDING', 'REPPROVE'],
        },
      ];
      currFilteredOption.options.sortBy = ['startDate'];
      currFilteredOption.options.sortDesc = [false];
      const result: any = await PtoService(token).timeOffRequestList(currentSchool.value, currFilteredOption);
      setRequestDataTableProps(result);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No data Found!');
    } finally {
      setLoading(false);
    }
  };

  const loadRequestsMonthScheduled = async () => {
    const result: any = await PtoService(token).timeOffRequestListApprovedThisMonth(currentSchool.value);

    setScheduledMonthData(result.data);
  };

  const handleApprove = async (id: string) => {
    try {
      await PtoService(token).timeOffRequestApprove(id);
      loadList(filteredOptions);
      loadRequests();
      loadRequestsMonthScheduled();
      toast.success('Approve Successfully!');
    } catch (err: any) {
      toast.error(err?.response?.data?.message || 'Approve error!');
      console.log(err);
    }
  };

  const handleRepprove = async (id: string) => {
    try {
      await PtoService(token).timeOffRequestRepprove(id);
      loadList(filteredOptions);
      loadRequests();
      loadRequestsMonthScheduled();
      toast.success('Repprove Successfully!');
    } catch (err: any) {
      toast.error(err?.response?.data?.message || 'Reprove error!');
      console.log(err);
    }
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChangeRequest = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: requestFilteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    SetRequestFilteredOptions(newFilteredOptions);
  };

  const handlePerRowsChangeRequest = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: requestFilteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    SetRequestFilteredOptions(newFilteredOptions);
  };

  const verifyDates = () => {
    const startDateMoment = moment(startDate).utc();
    const endDateMoment = moment(endDate).utc();
    let isBlackout = false;
    if (endDateMoment > startDateMoment) {
      let currentDate = moment(startDate).utc();
      while (endDateMoment >= currentDate) {
        if (!isBlackout) isBlackout = blackoutDates.includes(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'day');
      }
    }
    if (endDateMoment.format('YYYY-MM-DD') === startDateMoment.format('YYYY-MM-DD')) {
      if (!isBlackout) isBlackout = blackoutDates.includes(startDateMoment.format('YYYY-MM-DD'));
    }
    return isBlackout;
  };

  const handleSubmitNewTimeOffRequest = async (event: React.FormEvent) => {
    event.preventDefault();
    const isBlackoutDate = verifyDates();
    if (!isBlackoutDate) {
      return submitNewTimeOffRequest(event);
    }
    if (isBlackoutDate && !isAdmin(user) && !isDirector(user)) {
      return toast.error('You cannot request a time off for a blackout date.');
    }
    confirmAlertHelper({
      title: 'Submit',
      messageType: 'SAVE',
      message: 'Are you sure you would like to override this PTO request during blackout dates?',
      onClickYes: () => submitNewTimeOffRequest(event),
    });
  };

  const submitNewTimeOffRequest = async (event: React.FormEvent, confirmation: boolean = false) => {
    event.preventDefault();

    const data = {
      userId: selectedEmployeeId,
      schoolId: currentSchool.value,
      policyId,
      reason,
      startDate: moment(startDate).utc().format('YYYY-MM-DD'),
      endDate: moment(endDate).utc().format('YYYY-MM-DD'),
      hours: hours ? Number(hours) : Number(auxHours || 0),
      describe,
      confirmation: confirmation,
    };

    try {
      if (!ptoId) {
        await PtoService(token).timeOffRequestCreate(data);
      } else {
        await PtoService(token).timeOffRequestUpdate(ptoId, data);
      }
      setSelectedEmployeeId('');
      setPolicyId('');
      setReason('');
      setStartDate('');
      setEndDate('');
      setHours('');
      setDescribe('');
      toggleTimeOff();
      loadRequests();
      loadList(filteredOptions);
      reloadPendingRequestsData();
      toast.success('Saved Successfully!');
    } catch (err: any) {
      if (err?.response?.data?.errors?.code === 'NEED_CONFIRMATION') {
        return confirmAlertHelper({
          title: 'Time Off Request Creation',
          messageType: 'SAVE',
          message: 'This employee is still in their probationary period. Are you sure you wish to use PTO?',
          onClickYes: () => submitNewTimeOffRequest(event, true),
        });
      }
      toast.error(err?.response?.data?.message || 'Failed to save!');
    } finally {
      loadList(filteredOptions);
      loadRequests();
      loadRequestsMonthScheduled();
    }
  };

  const countBusinessDays = (hoursPerWorkday: number, initial?: any, end?: any) => {
    if (!initial || !end) return 0;

    const startBusinessDate = moment(initial).utc();
    const endBusinessDate = moment(end).utc();
    let counter = 0;
    const currentBusinessDate = startBusinessDate.clone();

    while (currentBusinessDate.isSameOrBefore(endBusinessDate)) {
      if (
        currentBusinessDate.day() !== 0 &&
        currentBusinessDate.day() !== 6 &&
        !hollidayDates.includes(currentBusinessDate.format('YYYY-MM-DD'))
      ) {
        counter++;
      }
      currentBusinessDate.add(1, 'days');
    }

    return counter * hoursPerWorkday;
  };

  const handleDateClick = (selectInfo: any) => {
    const date = new Date(selectInfo.date).toISOString().replace(/T.*$/, '');
    if (events.find((item) => item.start === date && item.groupId === 'blackout') && !isAdminUser) {
      return false;
    }
    toggleTimeOff({ startDate: date });
  };

  const [auxHours, setAuxHours] = useState(0);

  useEffect(() => {
    if (selectedEmployeeData) {
      const hours = countBusinessDays(selectedEmployeeData.ptoPolicy.hoursPerWorkday, startDate, endDate);

      setAuxHours(hours);
    }
  }, [startDate, endDate, selectedEmployeeData]);

  useEffect(() => {
    if (!Object.values(clickedEvent).length) {
      return;
    }

    switch (clickedEvent.event?.groupId) {
      case 'event':
        setRequestDetailsData(clickedEvent.data);
        setIsRequestDetailsOpen(true);
        break;
      case 'blackout':
        setClickedBlackoutData(clickedEvent.data);
        setBlackoutDatesModalViewMode(true);
        handleToggleBlackoutDatesModal();
        break;
      case 'holliday':
        setClickedHollidayData(clickedEvent.data);
        setModalHollidayOpen(true);
        break;
    }
  }, [clickedEvent]);

  useEffect(() => {
    if (clickedBlackoutId === '') {
      return;
    }
    setClickedBlackoutData(blackoutEvents.find((item: any) => item.id === clickedBlackoutId));
    setBlackoutDatesModalViewMode(true);
    handleToggleBlackoutDatesModal();
  }, [clickedBlackoutId]);

  useEffect(() => {
    if (clickedHollidayId === '') {
      return;
    }
    setClickedHollidayData(hollidayEvents.find((item: any) => item.id === clickedHollidayId));
    setModalHollidayOpen(true);
  }, [clickedHollidayId]);

  // const loadCallendarEvents = async (start: Moment, end: Moment) => {
  //   const result = await PtoService(token).timeOffRequestListByDates(currentSchool.value, start, end);
  //   console.log(result);
  // };

  const alterDatesSetCalendar = async (args: DatesSetArg) => {
    // await loadCallendarEvents(moment(args.start), moment(args.end));
    await loadRequests(moment(args.start).utc(), moment(args.end).utc());
  };

  return (
    <>
      <CommonHeader />
      <PtoRequestDetail
        data={requestDetailsData}
        canEdit={canEditRequest}
        isOpen={isRequestDetailsOpen}
        toggle={() => {
          setClickedEvent({});
          setIsRequestDetailsOpen(!isRequestDetailsOpen);
          return false;
        }}
        clickOnEdit={(data) => toggleTimeOff(data)}
        clickOnApprove={(data) => {
          setClickedEvent({});
          setIsRequestDetailsOpen(!isRequestDetailsOpen);
          confirmApprove({}, data.id);
        }}
        clickOnReprove={(data) => {
          setClickedEvent({});
          setIsRequestDetailsOpen(!isRequestDetailsOpen);
          confirmRepprove({}, data.id);
        }}
      />
      <Modal
        className="modal-dialog-centered"
        isOpen={modalBalanceOpen}
        toggle={() => setModalBalanceOpen(!modalBalanceOpen)}
        size={'xl'}
      >
        {balanceDetailData && (
          <>
            <div className="modal-header">
              <div className="d-flex align-items-center">
                <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
                  <img
                    src={balanceDetailData.user.picture}
                    alt="User Profile"
                    className="img-fluid"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </div>
                <span className="ml-2 text-primary font-weight-bold text-sm">{`${
                  balanceDetailData.user.firstName || '-'
                } ${balanceDetailData.user.lastName || '-'}`}</span>
              </div>
              <div className="d-flex p-1">
                <a
                  onClick={() => setModalBalanceOpen(!modalBalanceOpen)}
                  className="cursor-pointer"
                  style={{ color: '#525f7f' }}
                >
                  <i className="fas fa-times" />
                </a>
              </div>
            </div>
            <div className="modal-body mt-0 pt-0">
              <UserBalanceDetailForm
                data={balanceDetailData}
                editMode={userBalanceDetailFormEditMode}
                onSave={() => {
                  setUserBalanceDetailFormEditMode(false);
                  setModalBalanceOpen(!modalBalanceOpen);
                  loadList(filteredOptions);
                }}
              />
            </div>
          </>
        )}
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={modalHollidayOpen}
        toggle={() => {
          setClickedHollidayData({});
          setClickedHollidayId('');
          setModalHollidayOpen(!modalHollidayOpen);
        }}
        size={'md'}
      >
        {clickedHollidayData && (
          <>
            <div className="modal-header">
              <h4 className="modal-title">Holiday: {clickedHollidayData.name}</h4>
              <div
                onClick={() => {
                  setClickedHollidayData({});
                  setClickedHollidayId('');
                  setModalHollidayOpen(!modalHollidayOpen);
                }}
                className="d-flex p-1 cursor-pointer"
              >
                <i className="fas fa-times" />
              </div>
            </div>
            <div className="modal-body mt-0 pt-0">
              <PtoHollidayDetail data={clickedHollidayData} />
            </div>
          </>
        )}
      </Modal>
      <Modal className="modal-dialog-centered" isOpen={isOpenTimeOff} toggle={toggleTimeOff} size={'md'}>
        <div className="modal-header">
          <h4 className="modal-title">Record a new Time Off Request</h4>
          <div onClick={toggleTimeOff} className="d-flex p-1 cursor-pointer">
            <i className="fas fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleSubmitNewTimeOffRequest}>
            <Row style={{ borderBottom: '1px solid #dddddd' }}>
              <Col lg="12" xs="12">
                {!selectedEmployeeData && (
                  <FormGroup>
                    <Label className="form-control-label">Name</Label>
                    <Input
                      type="select"
                      onChange={(e) => setSelectedEmployeeId(e.target.value)}
                      value={selectedEmployeeId}
                      required
                    >
                      <option value=""></option>
                      {employees?.map((filter: any, index: number) => {
                        return (
                          <option value={filter.userId} key={`user-role-${index}`}>
                            {`${filter.user.firstName} ${filter.user.lastName}`}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                )}

                {selectedEmployeeData && (
                  <FormGroup>
                    <Label className="form-control-label">Name</Label>
                    <div className="d-flex align-items-center">
                      <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
                        <img
                          src={selectedEmployeeData.user.picture}
                          alt="User Profile"
                          className="img-fluid"
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      </div>
                      <span className="ml-2">{`${selectedEmployeeData.user.firstName || '-'} ${
                        selectedEmployeeData.user.lastName || '-'
                      }`}</span>
                      {isAdminUser && (
                        <Button
                          style={{
                            border: 'none',
                            boxShadow: 'none',
                            background: '#FFFFFF',
                            padding: 0,
                            marginLeft: 30,
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            setSelectedEmployeeData(null);
                            setSelectedEmployeeId('');
                          }}
                        >
                          <i className="fas fa-times" />
                        </Button>
                      )}
                    </div>
                  </FormGroup>
                )}
              </Col>

              <Col lg="12" xs="12">
                <FormGroup>
                  <Label className="form-control-label">Policy</Label>
                  <Input value={policyName ? policyName : '-'} disabled />
                </FormGroup>
              </Col>
              <Col lg="12" xs="12">
                <FormGroup>
                  <Label className="form-control-label">Reason</Label>
                  <Input type="select" value={reason} onChange={(event) => setReason(event.target.value)} required>
                    <option value=""></option>
                    <option value="VIP/Vacation/Sick">VIP/Vacation/Sick</option>
                    <option value="Jury Duty">Jury Duty</option>
                    <option value="Bereavement">Bereavement</option>
                    <option value="Day Off Non Paid">Day Off Non Paid</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <Label>How much time are you requesting?</Label>
                <Row>
                  <Col lg="6" xs="6">
                    <Button
                      style={{
                        border: timeRequested === 'One or more Days' ? '1px solid #5ca8ff' : '1px solid #000000',
                        color: timeRequested === 'One or more Days' ? '#5ca8ff' : '#000000',
                        fontSize: '12px',
                        width: '155px',
                        height: '75px',
                      }}
                      size="sm"
                      outline={timeRequested !== 'One or more Days'}
                      onClick={() => {
                        setStartDate('');
                        setEndDate('');
                        if (timeRequested !== 'One or more Days') {
                          setTimeRequested('One or more Days');
                        } else {
                          setTimeRequested('');
                        }
                      }}
                    >
                      One or more Days
                    </Button>
                  </Col>
                  <Col lg="6" xs="6">
                    <Button
                      style={{
                        border: timeRequested === 'Less than one day' ? '1px solid #5ca8ff' : '1px solid #000000',
                        color: timeRequested === 'Less than one day' ? '#5ca8ff' : '#000000',
                        fontSize: '12px',
                        width: '155px',
                        height: '75px',
                      }}
                      size="sm"
                      outline={timeRequested !== 'Less than one day'}
                      onClick={() => {
                        setStartDate('');
                        setEndDate('');
                        if (timeRequested !== 'Less than one day') {
                          setTimeRequested('Less than one day');
                        } else {
                          setTimeRequested('');
                        }
                      }}
                    >
                      Less than one day
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            {timeRequested === 'One or more Days' && (
              <>
                <Row className="mt-4">
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">Start Date</label>
                      <InputGroup className="input-group-alternative">
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Start Date',
                          }}
                          onChange={(newValue) => {
                            setStartDate(`${newValue}`);
                          }}
                          timeFormat={false}
                          value={startDate ? `${moment(startDate).utc().format('MM/DD/yyyy')}` : ''}
                          dateFormat={'MM/DD/yyyy'}
                          closeOnSelect={true}
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">End Date</label>
                      <InputGroup className="input-group-alternative">
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'End Date',
                          }}
                          onChange={(newValue) => {
                            setEndDate(`${newValue}`);
                          }}
                          timeFormat={false}
                          value={endDate ? `${moment(endDate).utc().format('MM/DD/yyyy')}` : ''}
                          dateFormat={'MM/DD/yyyy'}
                          initialValue={startDate}
                          closeOnSelect={true}
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">Hours</label>
                      <Input type="text" value={auxHours} disabled />
                      {reason !== 'Day Off Non Paid' ? (
                        <>
                          {Number(auxHours) >
                            Number(selectedEmployeeData?.ptoPolicy?.currentBalance) -
                              Number(selectedEmployeeData?.ptoPolicy?.scheduleHours) && (
                            <label className="text-sm text-danger">
                              You cannot request more hours than your current limit of{' '}
                              {Number(selectedEmployeeData?.ptoPolicy?.currentBalance) -
                                Number(selectedEmployeeData?.ptoPolicy?.scheduleHours) >
                              0
                                ? Number(selectedEmployeeData?.ptoPolicy?.currentBalance) -
                                  Number(selectedEmployeeData?.ptoPolicy?.scheduleHours)
                                : 0}
                              .{' Please change reason to "Day Off Non Paid".'}
                            </label>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label">Why are they taking time off?</label>
                      <Input type="text" value={describe} onChange={(event) => setDescribe(event.target.value)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-end pr-3">
                  {policyName && (
                    <Button
                      color="warning"
                      disabled={
                        auxHours > selectedEmployeeData?.ptoPolicy.currentBalance && !isAdmin(user) && !isDirector(user)
                      }
                    >
                      Save
                    </Button>
                  )}
                </Row>
              </>
            )}

            {timeRequested === 'Less than one day' && (
              <>
                <Row className="mt-4">
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Date</label>
                      <InputGroup className="input-group-alternative">
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Date',
                          }}
                          onChange={(newValue) => {
                            setStartDate(`${newValue}`);
                            setEndDate(`${newValue}`);
                          }}
                          timeFormat={false}
                          value={startDate ? `${moment(startDate).utc().format('MM/DD/yyyy')}` : ''}
                          dateFormat={'MM/DD/yyyy'}
                          closeOnSelect={true}
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Hours</label>
                      <Input
                        type="number"
                        value={hours}
                        onChange={(event) => setHours(event.target.value)}
                        max="8"
                        min="1"
                        required={true}
                        step={0.5}
                      />
                      {reason !== 'Day Off Non Paid' && !hollidayDates.includes(startDate) ? (
                        <>
                          {Number(hours) >
                            Number(selectedEmployeeData?.ptoPolicy?.currentBalance) -
                              Number(selectedEmployeeData?.ptoPolicy?.scheduleHours) && (
                            <label className="text-sm text-danger">
                              You cannot request more hours than your current limit of{' '}
                              {Number(selectedEmployeeData?.ptoPolicy?.currentBalance) -
                                Number(selectedEmployeeData?.ptoPolicy?.scheduleHours) >
                              0
                                ? Number(selectedEmployeeData?.ptoPolicy?.currentBalance) -
                                  Number(selectedEmployeeData?.ptoPolicy?.scheduleHours)
                                : 0}
                              .{' Please change reason to "Day Off Non Paid".'}
                            </label>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label">Why are they taking time off?</label>
                      <Input type="text" value={describe} onChange={(event) => setDescribe(event.target.value)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-end pr-3">
                  {policyName && (
                    <Button
                      color="warning"
                      disabled={
                        auxHours > selectedEmployeeData?.ptoPolicy.currentBalance && !isAdmin(user) && !isDirector(user)
                      }
                    >
                      Save
                    </Button>
                  )}
                </Row>
              </>
            )}
          </Form>
        </div>
      </Modal>
      <Container className="mt--8" fluid>
        <Row>
          <Col xs="0" md="3"></Col>
          <Col xs="6" md="3">
            {/* <button onClick={reloadPendingRequestsData}>dsa</button> */}
            <PenddingRequestInfo
              setRequestDetailsData
              setIsRequestDetailsOpen
              confirmApprove
              confirmRepprove
              ref={childRef}
            />
          </Col>
          <Col xs="6" md="3">
            {isMobile ? (
              <CardWidget
                title="Scheduled/this month"
                text="5"
                icon="fa-check"
                iconColor="primary"
                tag="h6"
                cursor="pointer"
                onClick={handleToggleModal}
              />
            ) : (
              <CardInfo
                count={scheduledMonthData.length}
                class="bg-success card-info-mg-button"
                title="Scheduled off this month"
                icon="fa-check"
                toggle={handleToggleModal}
              />
            )}

            <ModalContent
              title="Scheduled off this month"
              class="pt-0"
              modal={toggleModal}
              toggle={handleToggleModal}
              size="xl"
            >
              <Row>
                <Col xs="12">
                  <DataTable
                    columns={columnsScheduled}
                    data={scheduledMonthData}
                    header={false}
                    totalRows={pagedMeta.total}
                    loading={loading}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                  />
                </Col>
              </Row>
            </ModalContent>
          </Col>
          <Col xs="0" md="3"></Col>
          <Col className="text-right mt-3" xl="12">
            {isAdminUser && !isMobile && (
              <Button
                className="bg-default text-white mb-2"
                onClick={handleToggleBlackoutDatesModal}
                size={isMobile ? 'sm' : 'md'}
              >
                Add Blackout Dates
              </Button>
            )}
          </Col>
          <Col xl="12" className="bg-secondary shadow pt-3">
            <div className="calendar-app">
              <div className="calendar-app-sidebar">
                <div className="calendar-app-sidebar-section pb-2">
                  <label>
                    <input type="checkbox" checked={weekendsVisible} onChange={() => changeWeekendsVisible()}></input>
                    &nbsp;Show weekends?
                  </label>
                  {isAdminUser && isMobile && (
                    <Button
                      className="bg-default text-white float-right"
                      onClick={handleToggleBlackoutDatesModal}
                      size={isMobile ? 'sm' : 'md'}
                    >
                      Add Blackout Dates
                    </Button>
                  )}
                </div>
              </div>
              {/* <div className="calendar-app-main">
                          <PtoRequestsCallendar
                            isAdminUser
                            weekendsVisible={weekendsVisible}
                            reload={ptoCallendarReload}
                            eventClick={(e: any) => {
                              setClickedEvent(e);
                            }}
                            dateClick={handleDateClick}
                          />
                        </div> */}
              <div className="calendar-app-main">
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                  headerToolbar={{
                    left: 'prev,next',
                    center: 'title',
                    right: 'today',
                  }}
                  eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short',
                  }}
                  initialView="dayGridMonth"
                  editable={false}
                  selectable={false}
                  selectMirror={true}
                  dayMaxEvents={false}
                  weekends={weekendsVisible}
                  events={events}
                  eventContent={renderEventContent}
                  eventClick={(e) => {
                    setClickedEvent({
                      ...e,
                      ...{
                        data: (eventsInfo as any)[e.event.groupId as any].find((item: any) => item.id === e.event.id),
                      },
                    });
                  }}
                  dateClick={handleDateClick}
                  datesSet={alterDatesSetCalendar}
                />
              </div>
            </div>
          </Col>

          <Col xs="12" className="text-right mt-3">
            <Button
              style={{ background: '#ff7b00', color: '#FFFFFF' }}
              onClick={toggleTimeOff}
              size={isMobile ? 'sm' : 'md'}
            >
              Add Time Off
            </Button>
          </Col>
          <Col xs="12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === '1' ? 'active' : ''}`}
                  onClick={() => setActiveTab('1')}
                  style={customStyles.navLink}
                >
                  Balances
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '2' ? 'active' : ''}`}
                  onClick={() => setActiveTab('2')}
                  style={customStyles.navLink}
                >
                  Requests
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '3' ? 'active' : ''}`}
                  onClick={() => setActiveTab('3')}
                  style={customStyles.navLink}
                >
                  Scheduled
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="12">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <DataTable
                  columns={columnsBalance}
                  data={data}
                  header={false}
                  totalRows={pagedMeta.total}
                  loading={loading}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </TabPane>
            </TabContent>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="2">
                <DataTable
                  responsive={!isMobile}
                  columns={columnsRequests}
                  data={requestDataTableProp.data}
                  header={false}
                  totalRows={requestDataTableProp.meta.total | 0}
                  loading={loading}
                  onChangeRowsPerPage={handlePerRowsChangeRequest}
                  onChangePage={handlePageChangeRequest}
                />
              </TabPane>
            </TabContent>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="3">
                <DataTable
                  columns={columnsScheduled}
                  data={scheduledData}
                  header={false}
                  totalRows={pagedMeta.total}
                  loading={loading}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
      <BlackoutDatesForm
        toogle={handleToggleBlackoutDatesModal}
        change={() => {
          loadRequests();
        }}
        isOpen={blackoutDatesModal}
        data={clickedBlackoutData}
        viewMode={blackoutDatesModalViewMode}
      />
    </>
  );
};

export default Pto;

import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import RcTimePicker from 'rc-time-picker';
import moment from 'moment-timezone';
import { useEffect, useState, useContext } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';

type TimePickerProps = {
  showSecond?: boolean;
  format?: string;
  onChangeTime: (time: any) => void;
  defaultValue?: string;
  value?: string;
  minuteStep?: number;
  disabled?: boolean;
  name?: string;
};

const TimezonePicker = ({
  showSecond,
  format,
  onChangeTime,
  defaultValue,
  minuteStep = 5,
  disabled = false,
  name,
}: TimePickerProps) => {
  const { currentSchool = {} }: any = useContext(AuthContext);
  const isShowSecond = typeof showSecond === 'undefined' ? false : showSecond;
  const currentFormat = typeof format === 'undefined' ? 'h:mm a' : format;
  const now = moment();
  const [defaulTimeValue, setDefaulTimeValue] = useState<moment.Moment | undefined>(undefined);
  const defaultMinuteStep = minuteStep;
  const [showEmpty, setShowEmpty] = useState(false);

  useEffect(() => {
    if (typeof defaultValue === 'undefined') {
      const _now = now.tz(currentSchool.timezone);
      changeTime(_now);
      setDefaulTimeValue(_now);
    } else {
      if (currentSchool?.timezone) {
        setDefaulTimeValue(moment(defaultValue).tz(currentSchool.timezone));
      } else {
        setDefaulTimeValue(moment(defaultValue));
      }
    }
  }, [currentSchool, defaultValue]);

  const changeTime = (time: any) => {
    setShowEmpty(false);
    if (time === null) {
      onChangeTime(null);
      setShowEmpty(true);
      return;
    }
    if (moment()?.tz(currentSchool?.timezone)?.isBefore(time)) {
      // return;
    }
    if (onChangeTime) {
      const timeFormat = moment(time)?.format('YYYY-MM-DDTHH:mm:ss.SSS');
      const timezoneFormat = moment()?.tz(currentSchool?.timezone)?.format('Z');
      const nowFormat = now?.format('YYYY-MM-DDTHH:mm:ss.SSS');
      onChangeTime(
        time
          ? timeFormat && timezoneFormat
            ? timeFormat + timezoneFormat
            : timeFormat
          : nowFormat && timezoneFormat
          ? nowFormat + timezoneFormat
          : nowFormat,
      );
      setDefaulTimeValue(time);
    }
  };

  return (
    <>
      <InputGroup
        className={`input-group-alternative ${disabled ? 'disabled' : ''}`}
        style={{ border: '1px solid #cad1d7' }}
      >
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="far fa-clock"></i>
          </InputGroupText>
        </InputGroupAddon>
        {showEmpty && (
          <RcTimePicker
            className={`form-control ${disabled ? 'disabled' : ''}`}
            showSecond={isShowSecond}
            onChange={changeTime}
            allowEmpty={true}
            use12Hours
            minuteStep={defaultMinuteStep}
            disabled={disabled}
            name={name}
          />
        )}
        {!showEmpty && (
          <RcTimePicker
            className={`form-control ${disabled ? 'disabled' : ''}`}
            showSecond={isShowSecond}
            onChange={changeTime}
            format={currentFormat}
            defaultValue={defaulTimeValue}
            value={defaulTimeValue}
            allowEmpty={true}
            use12Hours
            minuteStep={defaultMinuteStep}
            disabled={disabled}
            name={name}
          />
        )}
      </InputGroup>
    </>
  );
};

export default TimezonePicker;

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import QrCode from '../../../components/Core/QrCode';

const QrCodeModal: React.FC<{
  currentUser: any;
  modalQr: boolean;
  toggleModalQr: () => void;
}> = ({ currentUser, modalQr, toggleModalQr }) => {
  return (
    <>
      <Modal isOpen={modalQr} toggle={toggleModalQr} centered>
        <ModalHeader toggle={toggleModalQr} className="pb-0">
          My Code
        </ModalHeader>
        <ModalBody className="pt-0 text-center">
          <QrCode />
          <span className="h2 font-weight-bold mx-auto">{`${currentUser.pin || '-'}`}</span>
        </ModalBody>
      </Modal>
    </>
  );
};

export default QrCodeModal;
